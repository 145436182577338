import { FC, useState } from 'react'
import { useCookies } from 'react-cookie'
import LazyLoad from 'react-lazyload'
import gql from 'graphql-tag'
import styled from 'styled-components'
import {
  NotificationScope,
  useGqlScreenHomeDesktopQuery,
} from 'generated/graphql'
import { useGqlCommunityCurrentUserQuery } from 'generated/graphql.community'
import BestStory from '@app.components/BestStory'
import HotLine from '@app.feature/home/components/HotLine/HotLine'
import LiveActivityInfoDesktop from '@app.feature/home/components/LiveActivityInfo/desktop/LiveActivityInfo.desktop'
import LiveActivityInfoLoadingDesktop from '@app.feature/home/components/LiveActivityInfo/desktop/LiveActivityInfoLoading.desktop'
import NewBannerDesktop from '@app.feature/home/components/NewBanner/desktop/NewBanner.desktop'
import PopularActivityDesktop from '@app.feature/home/components/PopularActivity/desktop/PopularActivity.desktop'
import PopularActivityLoadingDesktop from '@app.feature/home/components/PopularActivity/desktop/PopularActivityLoading.desktop'
import PopularChatRoomsDesktop from '@app.feature/home/components/PopularChat/desktop/PopularChatRooms.desktop'
import PopularRecruitDesktop from '@app.feature/home/components/PopularRecruit/desktop/PopularRecruit.desktop'
import PopularRecruitLoadingDesktop from '@app.feature/home/components/PopularRecruit/desktop/PopularRecruitLoading.desktop'
import RecommendActivityDesktop from '@app.feature/home/components/RecommendActivity/desktop/RecommendActivity.desktop'
import SignupModal from '@app.feature/home/desktop/home.signupModal/SignupModal.desktop'
import useSignupCookies, {
  SIGNUP_MODAL_COOKIE,
} from '@app.feature/home/hook/useSignupCookies'
import { BestStoryType } from 'app.constants/enums'
import AdvertiseBanner from 'components/AdvertiseBanner/screen/AdvertiseBanner'
import InstallAppBanner from '../components/InstallAppBanner/InstallAppBanner'
import LinkareerMagazine from '../components/LinkareerMagazine'
import ManagerReply from '../components/ManagerReply'
import MyActivities from '../components/MyActivities'
import RightBanner from '../components/RightBanner'
import ServiceFeedbackBanner from '../components/ServiceFeedbackBanner/ServiceFeedbackBanner'

gql`
  query gqlScreenHomeDesktop($notificationType: NotificationScope) {
    currentUser {
      isInitializedUserInfo
      ...MyActivities_currentUser
      ...PopularActivityDesktop_currentUser
    }
  }
`

const ScreenHomeDesktop: FC = () => {
  const [cookies] = useCookies()
  const { addSignupModalCookie } = useSignupCookies()
  const [isOpenSignupModal, setIsOpenSignupModal] = useState<boolean>(false)

  const { data, loading } = useGqlScreenHomeDesktopQuery({
    variables: { notificationType: NotificationScope.NOTIFICATION },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const canOpenModal =
        !cookies?.[SIGNUP_MODAL_COOKIE] &&
        data?.currentUser?.type === 'NORMAL' &&
        data.currentUser?.isInitializedUserInfo === false
      setIsOpenSignupModal(canOpenModal)
    },
  })

  const { data: communityUserData, loading: communityUserLoading } =
    useGqlCommunityCurrentUserQuery({
      variables: {
        isMypage: false,
      },
    })

  const handleModalClose = () => {
    addSignupModalCookie()
    setIsOpenSignupModal(() => false)
  }

  return (
    <StyledWrapper>
      <section className="main-section">
        <article className="lt-article">
          <HotLine />
          <div className="row-box">
            <div className="column-box">
              <LinkareerMagazine />
              <ManagerReply />
            </div>
            <NewBannerDesktop />
          </div>
        </article>
        <article className="rt-article">
          <MyActivities
            currentUser={data?.currentUser}
            communityUser={communityUserData}
            isLoading={loading || communityUserLoading}
          />
          <BestStory
            className="best-story"
            type={BestStoryType.ALL}
            isRadius
            isExpandBtn={false}
          />
        </article>
      </section>
      <RecommendActivityDesktop
        currentUser={data?.currentUser || undefined}
        loading={loading}
      />
      <LazyLoad height={'100%'} placeholder={<PopularActivityLoadingDesktop />}>
        <PopularActivityDesktop
          currentUser={data?.currentUser || undefined}
          loading={loading}
        />
      </LazyLoad>
      <LazyLoad height={'100%'} placeholder={<PopularRecruitLoadingDesktop />}>
        <PopularRecruitDesktop
          currentUser={data?.currentUser || undefined}
          loading={loading}
        />
      </LazyLoad>
      <LazyLoad height={'100%'}>
        <PopularChatRoomsDesktop />
      </LazyLoad>
      <LazyLoad
        height={'100%'}
        placeholder={<LiveActivityInfoLoadingDesktop />}
      >
        <LiveActivityInfoDesktop currentUser={data?.currentUser || undefined} />
      </LazyLoad>
      <aside>
        <InstallAppBanner />
        <RightBanner />
        <ServiceFeedbackBanner />
        <AdvertiseBanner
          code="pc-right-sidebar-all-of-page"
          placeholder={{ width: 160, height: 660 }}
          className="advertise-banner"
        />
      </aside>

      {isOpenSignupModal && (
        <SignupModal
          isModalOpen={isOpenSignupModal}
          handleModalClose={handleModalClose}
        />
      )}
    </StyledWrapper>
  )
}

export default ScreenHomeDesktop

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 70px;
  .main-section {
    display: flex;
    gap: 30px;
    max-width: 1100px;
    .lt-article {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .rt-article {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .best-story {
        max-width: 350px;
      }
    }
  }
  .advertise-banner {
    position: absolute;
    top: 614px;
    left: calc(100% + 20px);
  }

  .row-box {
    display: flex;
    gap: 20px;
  }
  .column-box {
    display: flex;
    flex-direction: column;
  }
`
