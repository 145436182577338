import { FC, Fragment } from 'react'
import gql from 'graphql-tag'
import {
  AdType,
  AdStatus,
  useRecommendActivityListQuery,
  ActivityScrap_CurrentUserFragment,
} from 'generated/graphql'
import RecommendActivityList from './RecommendActivityList'
import RecommendActivityListLoading from './RecommendActivityListLoading'

gql`
  fragment RecommendActivity_advertisement on Advertisement {
    id
    title
    outLink
    pcImage
    mobileImage
  }

  query RecommendActivityList(
    $adType: AdType!
    $status: AdStatus
    $withManager: Boolean!
  ) {
    advertisementList(filterBy: { adType: $adType, status: $status }) {
      activities {
        ...RecommendActivity_S_Banner_activity
        ...RecommendActivity_A_Banner_activity
        ...RecommendActivityItem_activity
      }
    }
  }
`

interface IFRecommendActivityDesktopProps {
  currentUser?: ActivityScrap_CurrentUserFragment
  loading?: boolean
}

const RecommendActivityDesktop: FC<IFRecommendActivityDesktopProps> = ({
  currentUser,
  loading,
}) => {
  const { data: sBannerData, loading: sBannerLoading } =
    useRecommendActivityListQuery({
      variables: {
        adType: AdType.RECOMMEND_S_BANNER,
        status: AdStatus.OPEN,
        withManager: false,
      },
    })
  const sBanner = sBannerData?.advertisementList?.activities?.[0]

  const { data: aBannerData, loading: aBannerLoading } =
    useRecommendActivityListQuery({
      variables: {
        adType: AdType.RECOMMEND_A_BANNER,
        status: AdStatus.OPEN,
        withManager: false,
      },
    })
  const aBanners = aBannerData?.advertisementList?.activities || []

  return (
    <Fragment>
      {loading && <RecommendActivityListLoading />}
      {!loading && (
        <RecommendActivityList
          sBanner={sBanner}
          aBanners={aBanners}
          sBannerLoading={sBannerLoading}
          aBannerLoading={aBannerLoading}
          currentUser={currentUser}
        />
      )}
    </Fragment>
  )
}

export default RecommendActivityDesktop
