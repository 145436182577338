import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import styled, { css } from 'styled-components'
import { useUpdateUserInitialInfoMutation } from 'generated/graphql'
import Modal from '@app.components/Modal'
import { useChattingContext } from '@app.feature/chatting/chattingContext/module/ChattingContext'
import signupModalSchema from '@app.feature/home/mobile/home.signupModal/schema/signupModalSchema'
import SignupSubmitButton from '@app.feature/signup/signup/components/SignupSubmitButton'
import FieldSchool from '@app.feature/signup/signup/components/signupInfo/FieldSchool'
import SignupInterest from '@app.feature/signup/signup/components/signupInterest/SignupInterest'
import CloseIcon from 'svgIcons/Close'
import { SignupModalType } from '../../type/signupModalType'
import SingupExitModal from './SingupExitModal.desktop'
import ReceiveGuideDesktop from './components/ReceiveGuide.desktop'

interface IFProps {
  isModalOpen: boolean
  handleModalClose: () => void
}
const SignupModal = ({ isModalOpen, handleModalClose }: IFProps) => {
  const { reportToChatting } = useChattingContext()
  const formMethods = useForm<SignupModalType>({
    mode: 'onChange',
    // TODO Type error: Type instantiation is excessively deep and possibly infinite.
    // @ts-ignore
    resolver: yupResolver(signupModalSchema),
  })

  const { handleSubmit } = formMethods

  const [updateUserInitialInfo] = useUpdateUserInitialInfoMutation({
    onCompleted: () => {
      reportToChatting({
        type: 'reportChatRefreshMyChatRoom',
      })
      handleModalClose()
    },
  })

  const handleSubmitTest = (data: SignupModalType) => {
    updateUserInitialInfo({
      variables: {
        input: {
          education: {
            finalEducationType: data.schoolType,
            schoolName: data.school.name,
            major: data.schoolMajor?.name,
          },
          phoneNumber: data.phoneNumber,
          smsAuthedToken: data.smsAuthedToken,
          preferJobCategoryIds: data.preferJobCategoryIds,
          preferActivityTypes: data.preferActivityTypes,
          preferOrganizationIds: data.preferOrganizationIds,
          subscribeSMS: data.subscribeSMS,
        },
      },
    })
  }

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const handleToggleConfirmModal = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen)
  }

  return (
    /* TODO Type error: Type instantiation is excessively deep and possibly infinite. */
    /* @ts-ignore  */
    <FormProvider {...formMethods}>
      <Modal open={isModalOpen} disableBackDropClose>
        <form onSubmit={handleSubmit(handleSubmitTest)}>
          <StyledWrapper>
            <main className="join-container">
              <i
                className="close-icon"
                role="button"
                onClick={handleToggleConfirmModal}
              >
                <CloseIcon width={16} height={16} fill="#333" />
              </i>
              <header className="join-title">
                <h2>
                  정보를 입력하면 저희가
                  <br />
                  <span>딱 맞는 커리어 정보</span>를 알려드릴게요!
                </h2>
              </header>
              <section>
                <SignupInterest />
              </section>
              <section className="field-school-section">
                <FieldSchool />
              </section>
              <section className="guide-section">
                <ReceiveGuideDesktop />
              </section>
              <section className="modal-submit-button">
                <SignupSubmitButton buttonText="선택 완료" />
              </section>
            </main>
          </StyledWrapper>
        </form>
        {isConfirmModalOpen && (
          <SingupExitModal
            isOpen={isConfirmModalOpen}
            onClickCancel={handleToggleConfirmModal}
            onClickConfirm={handleModalClose}
          />
        )}
      </Modal>
    </FormProvider>
  )
}

export default SignupModal

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 92vh;
    overflow-y: auto;
    ::-webkit-scrollbar {
      display: block;
    }
    ::-webkit-scrollbar-track,
    ::-webkit-scrollbar-button {
      display: none;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 15px;
      background-color: ${theme.background.bg_1};
    }
    width: 502px;
    padding: 25px 20px 40px;
    border-radius: 15px;
    background-color: white;

    .join-container {
      & > .divider {
        border: none;
        height: 1px;
        background-color: ${theme.background.bg_1};
        margin: 32px 0;
      }
      > .close-icon {
        display: flex;
        cursor: pointer;
        flex-direction: row-reverse;
      }
      .join-title {
        h2 {
          text-align: center;
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: -0.8px;
          color: #333;
          > span {
            color: ${theme.color.primary_2};
          }
        }
      }
      .field-school-section {
        margin-top: 24px;
      }
      .guide-section {
        margin-top: 36px;
      }
      .modal-submit-button {
        width: 120px;
        margin: 0 auto;
        padding-top: 8px;
      }
    }
    p {
      margin: 0 !important;
    }
    .pointer {
      cursor: pointer;
    }
  `}
`
