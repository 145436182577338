import { FC } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import {
  useGqlPopularActivityMobileQuery,
  AdType,
  AdvertisementOrderField,
  OrderDirection,
  AdStatus,
  ActivityListCardItem_CurrentUserFragment,
} from 'generated/graphql'
import ActivityListCardItem from 'components/ActivityListCardItem'
import ActivityLoadingItem from '../../ActivityLoadingItem/ActivityLoadingItem'

gql`
  fragment PopularActivityMobile_currentUser on User {
    ...ActivityListCardItem_currentUser
  }

  fragment PopularActivityMobile_advertisement on Advertisement {
    id
    title
    outLink
    pcImage
    mobileImage
  }

  query gqlPopularActivityMobile(
    $adType: AdType!
    $status: AdStatus
    $orderBy: AdvertisementOrder
    $withManager: Boolean!
  ) {
    advertisementList(
      filterBy: { adType: $adType, status: $status }
      orderBy: $orderBy
    ) {
      activities {
        ...ActivityListCardItem_activity
        advertisement(adType: $adType) {
          ...PopularActivityMobile_advertisement
        }
      }
      totalCount
    }
  }
`

interface IFPopularActivityMobileProps {
  currentUser?: ActivityListCardItem_CurrentUserFragment
  loading?: boolean
}

const PopularActivityMobile: FC<IFPopularActivityMobileProps> = ({
  currentUser,
  loading: currentUserLoading,
}) => {
  const { data, loading } = useGqlPopularActivityMobileQuery({
    variables: {
      adType: AdType.POPULAR_ACTIVITY,
      status: AdStatus.OPEN,
      orderBy: {
        field: AdvertisementOrderField.SEQ,
        direction: OrderDirection.ASC,
      },
      withManager: false,
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const activities = data?.advertisementList?.activities || []
  const isLoading = currentUserLoading || loading

  return (
    <StyledWrapper>
      <h2 className="title-text">금주의 인기공고</h2>
      {isLoading && (
        <div className="loading-wrapper">
          <ActivityLoadingItem time={2} className="loading-item" />
        </div>
      )}
      {!isLoading && (
        <div className="card-list">
          {activities.map((activity) => (
            <div key={activity.id} className="card-item">
              <ActivityListCardItem
                managerBadgeOn={false}
                activity={activity}
                cardTitle={activity?.advertisement?.title}
                isLoading={loading}
                currentUser={currentUser}
                cardImageProps={{
                  width: 155,
                  src: activity?.advertisement?.pcImage || '',
                  alt: activity?.advertisement?.title || '',
                }}
                {...(activity?.advertisement?.outLink && {
                  linkProps: {
                    onClick: () =>
                      window.open(activity?.advertisement?.outLink || '/'),
                  },
                })}
              />
            </div>
          ))}
        </div>
      )}
    </StyledWrapper>
  )
}

export default PopularActivityMobile

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0 20px 20px;
  overflow: hidden;
  background-color: #fff;
  border-bottom: 15px solid #fafafa;

  .loading-wrapper {
    display: flex;
    gap: 10px;
    padding-right: 20px;
    .loading-item {
      width: 100%;
    }
  }
  .title-text {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .card-list {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    padding-right: 20px;
    ::-webkit-scrollbar {
      display: none;
    }
    .card-item {
      width: calc(50% - 5px);
      flex-shrink: 0;
    }
  }
`
