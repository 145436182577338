import { TStoreSlice } from '@app.feature/jobCategory/type/jobCategoryStoreType'

type TOrderMap = {
  [key in string]: number
}

export type TOrderSlice = {
  order: number
  orderMap: TOrderMap
  getOrderByKey: (id: string) => number
}

const createOrderSlice: TStoreSlice<TOrderSlice> = (set, get) => ({
  order: 0,
  orderMap: {},
  getOrderByKey: (id) => {
    const { orderMap } = get()
    return orderMap?.[id]
  },
})

export default createOrderSlice
