import { FC } from 'react'
import styled from 'styled-components'
import ActivityListItem from 'components/ActivityListItem'

const LiveActivityInfoLoadingDesktop: FC = () => {
  return (
    <StyledWrapper>
      <h2 className="title-text">실시간 인기 채용</h2>
      <div className="loading-wrapper">
        {new Array(8).fill(0).map((_, index) => (
          <ActivityListItem key={index} isLoading />
        ))}
      </div>
    </StyledWrapper>
  )
}

export default LiveActivityInfoLoadingDesktop

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .title-text {
    font-size: 20px;
    font-weight: bold;
  }
  .loading-wrapper {
    display: flex;
    flex-direction: column;
  }
`
