import { FC } from 'react'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import get from 'lodash/get'
import Link from 'next/link'
import styled from 'styled-components'
import { ManagerReply_ReplyFragment } from 'generated/graphql'
import { ACTIVITY_TYPE_TEXT } from 'constants/typeText'

interface IFManagerReplyItemProps {
  reply: ManagerReply_ReplyFragment
}

const getDday = (closeDate?: number | null) => {
  const nowDate = new Date()
  const day = differenceInCalendarDays(
    closeDate || new Date(),
    nowDate.getTime(),
  )
  if (day > 0) {
    return `D-${day > 999 ? 999 : day}`
  }
  if (day === 0) {
    return '오늘마감'
  }
  return '모집마감'
}

const ManagerReplyItem: FC<IFManagerReplyItemProps> = ({ reply }) => {
  const activity = reply.activity
  const parent = reply.parent
  const activityTypeText = get(ACTIVITY_TYPE_TEXT, activity?.type || '', '')

  return (
    <Link
      href="/activity/[id]#activityReply"
      as={`/activity/${activity?.id}#activityReply`}
      passHref
    >
      <StyledWrapper>
        <div className="reply-info">
          <div className="reply-title-container">
            <div className="activity-type">
              <p className="bold-text">{activityTypeText}</p>
            </div>
            <div className="d-day-container">
              <p className="bold-text red-text">
                {getDday(activity?.recruitCloseAt)}
              </p>
            </div>
            <div className="activity-title-container">
              <p className="activity-title ellipsis">{activity?.title}</p>
            </div>
          </div>
          <div className="question-container">
            <div className="ellipsis">
              <span className="red-text bold-text prefix">Q</span>
              <span className="bold-text question-name">{parent?.name}</span>
              <span className="question-content">{parent?.content}</span>
            </div>
          </div>
          <div className="answer-container">
            <div className="ellipsis">
              <span className="blue-text bold-text prefix">A</span>
              <span className="bold-text question-name">{reply?.name}</span>
              <span className="question-content">{reply?.content}</span>
            </div>
          </div>
        </div>
      </StyledWrapper>
    </Link>
  )
}

export default ManagerReplyItem

const StyledWrapper = styled.a`
  cursor: pointer;
  display: flex;
  width: 100%;
  max-width: 340px;
  height: 120px;

  p {
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.6px;
  }

  .reply-info {
    flex: 1;
    max-width: 315px;
  }

  .reply-title-container {
    height: 40px;
    border-radius: 5px;
    background: #f4f4f4;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
    padding: 0 20px;
  }

  .activity-type {
    padding-right: 5px;
  }

  .d-day-container {
    padding-right: 15px;
  }

  .activity-title-container {
    width: auto;
    max-width: calc(100% - 120px);
    overflow: hidden;
  }

  .bold-text {
    font-size: 14px;
    font-weight: bold;
  }

  .question-container {
    width: 355px;
    margin-bottom: 15px;
  }

  .answer-container {
    width: 360px;
  }

  .activity-title {
    font-size: 16px !important;
  }

  .ellipsis {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .red-text {
    font-size: 14px;
    color: #ef2929;
  }

  .blue-text {
    font-size: 14px;
    color: #01a0ff;
  }

  .prefix {
    padding-right: 10px;
  }

  .question-name {
    padding-right: 10px;
  }

  .question-content {
    font-size: 14px;
  }
`
