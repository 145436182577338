import { merge } from 'lodash'
import { TJobCategoryState } from '../../type/jobCategoryStoreType'
import mergeDeepIdsByDepth from './mergeDeepIdsByDepth'

interface IFConvertAbleCategory {
  id: string
  name: string
  seq?: number | null
  children?: (IFConvertAbleCategory | null)[] | null
  parent?: IFConvertAbleCategory | null
}

const convertCategoryToState = (
  categories: (IFConvertAbleCategory | null)[],
  depth: number,
): Partial<TJobCategoryState> => {
  let result: Partial<TJobCategoryState> = {}
  categories.map((category) => {
    if (category) {
      const { id, name, seq, children, parent } = category

      if (children) {
        const childrenState = convertCategoryToState(children, depth + 1)
        result = {
          ...result,
          categories: merge(result.categories, childrenState.categories),
          idsByDepth: mergeDeepIdsByDepth(
            result.idsByDepth,
            childrenState.idsByDepth,
          ),
        }
      }

      result = {
        ...result,
        idsByDepth: {
          ...result.idsByDepth,
          [depth]: [...(result.idsByDepth?.[depth] || []), id],
        },
        categories: {
          ...result.categories,
          [id]: {
            id,
            childrenIds: children?.map(
              (childrenCategory) => childrenCategory?.id || '',
            ),
            depth,
            isSelect: false,
            name,
            parentId: parent?.id,
            seq: seq ?? 1,
            selectedChildrenIds: [],
          },
        },
      }
    }
  })

  return result
}

export default convertCategoryToState
