import { FC, Fragment } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import {
  ActivityScrap_CurrentUserFragment,
  RecommendActivity_A_Banner_ActivityFragment,
} from 'generated/graphql'
import ActivityListCardItem from 'components/ActivityListCardItem'

gql`
  fragment RecommendActivity_A_Banner_activity on Activity {
    ...ActivityListCardItem_activity
    advertisement(adType: $adType) {
      ...RecommendActivity_advertisement
    }
  }
`

interface IFA_BannerProps {
  activities?: Array<RecommendActivity_A_Banner_ActivityFragment>
  currentUser?: ActivityScrap_CurrentUserFragment
  loading: boolean
}

const A_Banner: FC<IFA_BannerProps> = ({
  activities,
  currentUser,
  loading,
}) => {
  return (
    <Fragment>
      {(loading || !activities) &&
        new Array(6).fill('').map((_, index) => (
          <BannerItem key={`A_banner-loading-${index}`}>
            <ActivityListCardItem isLoading />
          </BannerItem>
        ))}
      {!loading &&
        activities?.map((activity) => {
          const aBanner = activity?.advertisement

          return (
            <BannerItem key={aBanner?.id || activity?.id}>
              <ActivityListCardItem
                activity={activity}
                cardTitle={aBanner?.title}
                currentUser={currentUser}
                cardImageProps={{
                  src: aBanner?.pcImage || '',
                  width: 360,
                  alt: aBanner?.title || '',
                }}
                managerBadgeOn={false}
                {...(aBanner?.outLink && {
                  linkProps: {
                    onClick: () => window.open(aBanner?.outLink || '/'),
                  },
                })}
              />
            </BannerItem>
          )
        })}
    </Fragment>
  )
}

export default A_Banner

const BannerItem = styled.div`
  width: 100%;
  max-width: 276px;
  padding: 0 12px 50px 12px;
  flex-shrink: 0;
`
