import { forwardRef, memo, useCallback } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react'
import { AdInfoList_AdFragment } from 'generated/graphql'

SwiperCore.use([Autoplay, Navigation, Pagination])

interface IFNewBannerSwiperProps {
  mainDesktopAdList: AdInfoList_AdFragment[]
  totalCount: number
}

const NewBannerSwiper = forwardRef<SwiperClass, IFNewBannerSwiperProps>(
  ({ mainDesktopAdList, totalCount }, ref) => {
    const router = useRouter()

    const handleClickAd = useCallback(
      (url: string) => {
        window.open(url)
      },
      [router],
    )

    return (
      <Swiper
        modules={[Autoplay, Navigation, Pagination]}
        slidesPerView={1}
        loop={true}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        key="swiper-new-banner-key"
        onSwiper={(swiper) => {
          // TODO type fix
          // @ts-ignore
          ref.current = swiper
        }}
        pagination={{
          clickable: true,
          type: 'fraction',
          el: 'custom-pagination',
        }}
      >
        {mainDesktopAdList.map((ad, index) => {
          const slideId = `swiper-new-banner-slide-${ad.id}`
          const landingUrl = ad.activity?.id
            ? `/activity/${ad.activity?.id ?? ''}`
            : router.pathname

          return (
            <SwiperSlide key={slideId} id={slideId}>
              <StyledMainAd>
                <div className="custom-pagination">{`${
                  index + 1
                } / ${totalCount}`}</div>
                <Link href={landingUrl}>
                  <img
                    src={ad.image?.url}
                    alt={ad.image?.name}
                    onClick={() => handleClickAd(ad.campaign?.landingURL ?? '')}
                  />
                </Link>
              </StyledMainAd>
            </SwiperSlide>
          )
        })}
      </Swiper>
    )
  },
)

export default memo(NewBannerSwiper)

const StyledMainAd = styled.div`
  position: relative;

  > img {
    cursor: pointer;
    width: 345px;
    height: 500px;
  }

  .custom-pagination {
    position: absolute;
    z-index: 1;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 22px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 11px;
    padding: 2px 4px;
    right: 25px;
    bottom: 5%;
    color: white;
    font-size: 12px;
    font-weight: bold;
    line-height: 2;
  }
`
