import { FC } from 'react'
import gql from 'graphql-tag'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import { ArrowRight } from 'svgIcons'
import { MyActivities_CurrentUserFragment } from 'generated/graphql'
import {
  GqlCommunityCurrentUserQuery,
  MentorAuthenticationStatus,
} from 'generated/graphql.community'
import Button from '@app.components/Button'
import { CAREER_MANAGEMENT_PATH_NAME } from 'app.constants/careerPathname'
import UserAvatar from 'components/UserAvatar'
import ApproveMentorIcon from 'svgIcons/ApproveMentorIcon'
import MentorReplyIcon from 'svgIcons/MentorReplyIcon'
import {
  LINKAREER_API_BASE_URL,
  LINKAREER_BASE_URL,
  XEN_HOSTNAME,
} from 'utils/config'

gql`
  fragment MyActivitiesUser_currentUser on User {
    profile {
      id
      identificationImage {
        id
        url
      }
    }
    customizedActivities {
      totalCount
      nodes {
        id
        title
        organizationName
        isScrapped
        ...ActivityScrap_activity
      }
    }
    scrapCount
  }
`

interface IFMyActivitiesUserProps {
  currentUser: MyActivities_CurrentUserFragment
  communityUser: GqlCommunityCurrentUserQuery
}

const MyActivitiesUser: FC<IFMyActivitiesUserProps> = ({
  currentUser,
  communityUser,
}) => {
  return (
    <StyledWrapper>
      <div className="user-wrapper">
        <Link href="/profile">
          <a>
            <UserAvatar
              avatarProps={{ className: 'user-avatar' }}
              currentUser={currentUser}
            />
          </a>
        </Link>
        <div className="user-info">
          <div className="user-name">{currentUser.username}</div>
          <div className="customized-activities">
            {communityUser.currentUser?.mentorAuthenticationStatus ===
              MentorAuthenticationStatus.APPROVED && (
              <mark className="approved-badge">
                <ApproveMentorIcon width={16} height={16} />
                <span> 멘토</span>
              </mark>
            )}
            <Link href={`${CAREER_MANAGEMENT_PATH_NAME}?section=info`}>
              <a className="link-career-management">
                스펙 정리하기
                <ArrowRight width={5} height={9} />
              </a>
            </Link>
          </div>
        </div>
        <Link
          href={`${LINKAREER_API_BASE_URL}/logout?next=${LINKAREER_BASE_URL}`}
        >
          <Button
            className="logout-button"
            variant="contained"
            color="lightGrey"
          >
            로그아웃
          </Button>
        </Link>
      </div>
      <div className="action-box">
        <Link
          href={`https://${XEN_HOSTNAME}/storage?tab=message&type=received`}
          as={`https://${XEN_HOSTNAME}/storage?tab=message&type=received`}
        >
          <a className="action-item">
            쪽지
            <span className="count">
              {communityUser.messageUnreadCount || 0}
            </span>
          </a>
        </Link>
        <Link href="/notifications" as="/notifications">
          <a className="action-item">
            알림
            <span className="count">
              {currentUser?.notificationUnreadCount || 0}
            </span>
          </a>
        </Link>
        <Link
          href={`https://${XEN_HOSTNAME}/storage?tab=post&type=all`}
          as={`https://${XEN_HOSTNAME}/storage?tab=post&type=all`}
        >
          <a className="action-item">작성한 글</a>
        </Link>
        <Link
          href={`https://${XEN_HOSTNAME}/storage?tab=comment&type=comment`}
          as={`https://${XEN_HOSTNAME}/storage?tab=comment&type=comment`}
        >
          <a className="action-item">작성한 댓글</a>
        </Link>
      </div>
      <div className="action-box">
        <Link
          href={`https://${XEN_HOSTNAME}/new-post?boardSlug=mentor_campus&mentorWrite=true`}
          as={`https://${XEN_HOSTNAME}/new-post?boardSlug=mentor_campus&mentorWrite=true`}
        >
          <a className="action-item">멘토에게 질문하기</a>
        </Link>
        {communityUser.currentUser?.mentorAuthenticationStatus ===
        MentorAuthenticationStatus.APPROVED ? (
          <Link href="/profile/mentor/reply" as="/profile/mentor/reply">
            <a className="action-item">
              <MentorReplyIcon width={16} height={16} />
              <p>멘토 답변하기</p>
            </a>
          </Link>
        ) : (
          <Link href="/profile" as="/profile">
            <a className="action-item">멘토 인증하기</a>
          </Link>
        )}
      </div>
    </StyledWrapper>
  )
}

export default MyActivitiesUser

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    .user-wrapper {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 17px;

      .user-avatar {
        width: 48px;
        height: 48px;
      }

      .link-career-management {
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
        color: #01a0ff;
        display: flex;
        align-items: baseline;
        gap: 6.5px;
      }

      .user-info {
        width: 56%;

        .user-name {
          margin-bottom: 4px;
          max-width: 220px;
          font-size: 16px;
          font-weight: 600;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .customized-activities {
          font-size: 12px;
          display: flex;
          align-items: center;

          & > p {
            height: 21px;
            font-size: 12px;
            font-weight: normal;
            line-height: 19px;
            letter-spacing: -0.48px;
            text-align: left;
            color: ${theme.color.text_1};
          }

          & > .approved-badge {
            min-width: 47px;
            height: 21px;
            border-radius: 5px;
            background-color: ${theme.background.bg_4};
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1px;
            margin-right: 8px;

            & > span {
              height: 21px;
              font-size: 11px;
              font-weight: bold;
              line-height: 19px;
              letter-spacing: -0.44px;
              text-align: left;
              color: ${theme.color.primary_2};
            }
          }

          .customized-activities-count {
            height: 21px;
            color: ${theme.color.primary_2};
            line-height: 19px;
            margin-left: 5px;
            margin-right: 5px;
          }
          .expendMoreBold {
            font-size: 10px;
          }
        }
      }
      .logout-button {
        font-size: 10px;
        color: #999;
        padding: 5px 8px;
        border-radius: 3px;
      }
    }
    .action-box {
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      background: #f9f9f9;
      border-top: 1px solid #e8e8e8;

      .action-item {
        heiht: 16px;
        width: 50%;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: ${theme.color.text_1};
        border-right: 1px solid #e8e8e8;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2px;
        padding: 0;

        &:last-child {
          border-right: none;
        }

        & > p {
          height: 16px;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: ${theme.color.text_1};
        }

        .count {
          font-size: 12px;
          font-weight: bold;
          line-height: 14px;
          text-align: center;
          color: #ef2929;
          margin-left: 3px;
        }
      }
    }
  `}
`
