import { uniq } from 'lodash'
import { TJobCategoryState } from '../../type/jobCategoryStoreType'

const getSelectedCategoriesIncludeParent = (
  jobCategoryState: TJobCategoryState,
): string[] => {
  const categories = jobCategoryState.categories
  const selectedIds = jobCategoryState.showSelectedIds

  const parseIds = (id: string, result: string[]): string[] => {
    const target = categories[id]
    if (!target) {
      return result
    }
    if (target.parentId) {
      result = parseIds(target.parentId, result)
    }

    result.push(id)
    return result
  }

  let result: string[] = []

  selectedIds.map((id) => {
    result = parseIds(id, result)
  })

  return uniq(result)
}

export default getSelectedCategoriesIncludeParent
