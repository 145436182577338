import { FC } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import BestStoryEmpty from './BestStoryEmpty'
import BestStoryLoading from './BestStoryLoading'

export type TBestStoryItem = {
  href: string
  content: string
  commentCount: number
}

type Tprops = {
  data: TBestStoryItem[]
  loading: boolean
}

const BestStoryList: FC<Tprops> = ({ data, loading }) => {
  if (loading) return <BestStoryLoading />
  if (!loading && !data.length) return <BestStoryEmpty />

  return (
    <StyledWrapper>
      {data.map((item, idx) => (
        <Link key={idx} href={item.href}>
          <a className="item">
            <div className="lt">{`${idx + 1}`.padStart(2, '0')}</div>
            <div className="rt">{item.content}</div>
            <div className="comment-count">({item.commentCount})</div>
          </a>
        </Link>
      ))}
    </StyledWrapper>
  )
}

export default BestStoryList

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 20px;
  .item {
    display: flex;
    font-size: 14px;
    .lt {
      font-weight: bold;
      padding-right: 7px;
    }
    .rt {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .comment-count {
      color: #ef2929;
      padding-left: 2px;
    }
  }
`
