import { TIdsByDepth } from '../../type/jobCategoryStoreType'

const mergeDeepIdsByDepth = (
  origin: TIdsByDepth | undefined,
  target: TIdsByDepth | undefined,
): TIdsByDepth => {
  const result: TIdsByDepth = origin || {}

  const keys = Object.keys(origin || target || {}).map((key) => Number(key))

  keys.map((key) => {
    result[key] = [...(result?.[key] || []), ...(target?.[key] || [])]
  })

  return result
}

export default mergeDeepIdsByDepth
