import { FC } from 'react'
import styled from 'styled-components'
import LinkareerMagazineLoadingItem from './LinkareerMagazineLoadingItem'

const COUNT = 3

const LinkareerMagazineLoading: FC = () => {
  return (
    <StyledWrapper>
      <p className="loading-linkareer-magazine-title">합격자료</p>
      {Array(COUNT)
        .fill(0)
        .map((_, index) => (
          <LinkareerMagazineLoadingItem
            key={`magazine-loading-item-${index}`}
          />
        ))}
    </StyledWrapper>
  )
}

export default LinkareerMagazineLoading

const StyledWrapper = styled.div`
  margin-bottom: 18px;
  .loading-linkareer-magazine-title {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.8px;
    margin-bottom: 15px;
    cursor: default;
  }
`
