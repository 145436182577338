import { FC, Fragment, useState } from 'react'
import gql from 'graphql-tag'
import Image from 'next/image'
import styled, { css } from 'styled-components'
import { PopularChatItemMobile_ChatRoomFragment } from 'generated/graphql'
import Button from '@app.components/Button'
import ChatAppModal from '@app.components/modals/ChatAppModal/ChatAppModal'
import { formatBadgeTextPerActivityType } from '@app.feature/chatting/chattingContext/module/formatBadgeTextPerActivityType'

gql`
  fragment PopularChatItemMobile_chatRoom on ChatRoom {
    id
    name
    activityTypeId
    imageUrl
    isActive
    memberCount
    isManagerRecentChatMessageSentInPopularChatRoom
    isMentorRecentChatMessageSentInPopularChatRoom
  }
`

interface IFPopularChatItemMobileProps {
  data: PopularChatItemMobile_ChatRoomFragment
}

const PopularChatItemMobile: FC<IFPopularChatItemMobileProps> = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const renderChatBadge = () => {
    if (data.isManagerRecentChatMessageSentInPopularChatRoom) {
      return (
        <Fragment>
          <Image
            src="/static/images/chat/chat-active-manager.svg"
            width={16}
            height={16}
            alt={`${formatBadgeTextPerActivityType(data.activityTypeId)} 뱃지`}
          />
          <span className="chat-manager-active">{`${formatBadgeTextPerActivityType(
            data.activityTypeId,
          )}`}</span>
        </Fragment>
      )
    }

    if (data.isMentorRecentChatMessageSentInPopularChatRoom) {
      return (
        <Fragment>
          <Image
            src="/static/images/chat/chat-active.svg"
            width={16}
            height={16}
            alt="현직자 대화중 뱃지"
          />
          <span className="chat-active">현직자 대화중</span>
        </Fragment>
      )
    }

    if (data.isActive) {
      return <span className="chat-active">지금 대화중</span>
    }
  }

  return (
    <StyledWrapper>
      <div className="img-box">
        <img
          src={data.imageUrl || '/static/images/channel/enterprise.png'}
          alt="기업로고"
        />
      </div>
      <div className="chat-info-box">
        <div className="badge-container">{renderChatBadge()}</div>
        <div className="chat-name">
          <div className="chat-name">{data.name}</div>
        </div>
        <div className="chat-member">{data.memberCount}명</div>
      </div>
      <Button
        className="chat-btn"
        color="transparent"
        onClick={() => setIsModalOpen(true)}
      >
        채팅방 참여하기
      </Button>
      <ChatAppModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        chatRoomId={data.id}
      />
    </StyledWrapper>
  )
}

export default PopularChatItemMobile

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 155px;
    min-height: 220px;
    border: solid 1px #ddd;
    border-radius: 5px;
    padding: 10px;
    :last-child {
      margin-right: 20px;
    }

    .img-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 56px;
      img {
        max-width: 100%;
        max-height: 50px;
      }
    }
    .chat-info-box {
      margin-top: 16px;
      > .badge-container {
        display: flex;
        min-height: 16px;
        margin-bottom: 4px;
        > .chat-active {
          color: ${theme.color.primary_2};
          font-size: 12px;
          font-weight: bold;
          letter-spacing: -0.48px;
        }
        > .chat-manager-active {
          color: #30b243;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: -0.48px;
        }
      }
    }
    .chat-name {
      font-size: 13px;
      font-weight: bold;
      line-height: 1.38;
      letter-spacing: -0.52px;
      margin-bottom: 2px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .chat-member {
      display: flex;
      font-size: 12px;
      line-height: 1.67;
      letter-spacing: -0.48px;
      color: #666;
      margin-bottom: 10px;

      p {
        font-size: 1px;
        padding-top: 1px;
      }
    }
    .chat-btn {
      width: 100%;
      height: 35px;
      color: ${theme.color.primary_2};
      font-size: 12px;
      font-weight: 500;
      line-height: 2.5;
      letter-spacing: -0.48px;
      border: solid 1px ${theme.color.primary_2};
      :hover {
        background-color: transparent;
      }
    }
  `}
`
