import { TJobCategoryState } from '../../type/jobCategoryStoreType'
import convertCategoryToState from './convertCategoryToState'

const resetState = (
  jobCategoryState: TJobCategoryState,
  isHard?: boolean,
): Partial<TJobCategoryState> => {
  const { originalCategoriesData } = jobCategoryState

  const clearCategoryState = convertCategoryToState(originalCategoriesData, 1)

  if (isHard) {
    return {
      originalCategoriesData: [],
      idsByDepth: [],
      showSelectedIds: [],
      activeIds: [],
      ...clearCategoryState,
    }
  }

  const result: Partial<TJobCategoryState> = {
    categories: clearCategoryState.categories,
    showSelectedIds: [],
  }

  return result
}

export default resetState
