export enum BestStoryType {
  CHANNEL = 'channel',
  COMMUNITY = 'community',
  ALL = 'all',
}

export enum ChannelUserType {
  NORMAL = 'normal',
  CHANNEL = 'channel',
  OWNER = 'owner',
}
