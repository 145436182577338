import { FC, useMemo } from 'react'
import { useDomain } from 'context'
import Link from 'next/link'
import styled from 'styled-components'
import { ArrowRight } from 'svgIcons'
import { BestStoryType } from 'app.constants/enums'
import ChannelBestNews from '../ChannelBestNews/ChannelBestNews'
import CommunityBestPost from '../CommunityBestPost/CommunityBestPost'
import useCurrentMenu from './hooks/useCurrentMenu'

const BestStoryAll: FC = () => {
  const { protocol, xen } = useDomain()
  const { currentMenu, changeCurrentMenu } = useCurrentMenu()

  const expandLabel = useMemo(() => {
    if (currentMenu === BestStoryType.CHANNEL) return '채널 더보기'
    if (currentMenu === BestStoryType.COMMUNITY) return '커뮤니티 더보기'

    return '더보기'
  }, [currentMenu])

  return (
    <StyledWrapper>
      <div className="menu-box">
        <button
          className="menu-title"
          data-active={currentMenu === BestStoryType.COMMUNITY}
          onClick={() => changeCurrentMenu(BestStoryType.COMMUNITY)}
        >
          커뮤니티
        </button>
        <button
          className="menu-title"
          data-active={currentMenu === BestStoryType.CHANNEL}
          onClick={() => changeCurrentMenu(BestStoryType.CHANNEL)}
        >
          채널
        </button>
      </div>
      <div className="list-box">
        {currentMenu === BestStoryType.COMMUNITY && (
          <>
            <CommunityBestPost />
            <a href={`${protocol}://${xen}/best`} className="expand-btn">
              {expandLabel}
              <ArrowRight />
            </a>
          </>
        )}
        {currentMenu === BestStoryType.CHANNEL && (
          <>
            <ChannelBestNews />
            <Link
              href={{
                pathname: '/channel',
                query: {
                  tab: 'home',
                  'order-by': 'popular',
                },
              }}
            >
              <a className="expand-btn">
                {expandLabel}
                <ArrowRight />
              </a>
            </Link>
          </>
        )}
      </div>
    </StyledWrapper>
  )
}

export default BestStoryAll

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  .menu-box {
    display: flex;
    border-bottom: 1px solid #e8e8e8;
    .menu-title {
      width: 100%;
      height: 40px;
      border: none;
      background: none;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      &[data-active='true'] {
        color: #01a0ff;
        font-weight: bold;
        border-bottom: 2px solid #01a0ff;
      }
      :hover {
        color: #01a0ff;
      }
    }
  }
  .list-box {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .expand-btn {
      cursor: pointer;
      font-size: 12px;
      border: none;
      background: none;
      width: fit-content;
      margin: 20px;
      padding: 0px;
      svg {
        width: 10px;
        height: 10px;
        transform: translateY(0.5px);
      }
    }
  }
`
