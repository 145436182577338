import { FC } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import SwiperCore, { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  AdType,
  AdStatus,
  useRecommendActivityListQuery,
  ActivityScrap_CurrentUserFragment,
} from 'generated/graphql'
import RecommendActivityItem from './RecommendActivityItem'
import RecommendActivityInfo from './RecommendActivityItemInfo'

gql`
  query RecommendActivityList(
    $adType: AdType!
    $status: AdStatus
    $withManager: Boolean!
  ) {
    advertisementList(filterBy: { adType: $adType, status: $status }) {
      activities {
        ...RecommendActivity_S_Banner_activity
        ...RecommendActivity_A_Banner_activity
        ...RecommendActivityItem_activity
      }
    }
  }
`

interface IFRecommendActivityMobileProps {
  currentUser?: ActivityScrap_CurrentUserFragment
  loading?: boolean
}

const RecommendActivityMobile: FC<IFRecommendActivityMobileProps> = ({
  currentUser,
  loading,
}) => {
  SwiperCore.use([Pagination, Autoplay])

  const { data: sBannerData, loading: sBannerLoading } =
    useRecommendActivityListQuery({
      variables: {
        adType: AdType.RECOMMEND_S_BANNER,
        status: AdStatus.OPEN,
        withManager: false,
      },
    })
  const sBanner = sBannerData?.advertisementList?.activities?.[0]

  const { data: aBannerData, loading: aBannerLoading } =
    useRecommendActivityListQuery({
      variables: {
        adType: AdType.RECOMMEND_A_BANNER,
        status: AdStatus.OPEN,
        withManager: false,
      },
    })
  const aBanners = aBannerData?.advertisementList?.activities || []
  const recommendActivities = [sBanner].concat(aBanners)
  const isBannerLoading = sBannerLoading || aBannerLoading || loading

  if (!recommendActivities?.length) {
    return <RecommendActivityItem loading={isBannerLoading} />
  }

  return (
    <StyledWrapper>
      <Swiper
        pagination={{
          clickable: true,
          type: 'fraction',
          el: 'custom-pagination',
        }}
        loop
        slidesPerView={1}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
      >
        {recommendActivities?.map((activity, activityIndex) => {
          return (
            <SwiperSlide key={`recommend-activity-item-mobile-${activity?.id}`}>
              <div className="activity-container">
                <RecommendActivityItem
                  activity={activity}
                  currentUser={currentUser}
                  totalSides={recommendActivities.length}
                  activeSlide={activityIndex + 1}
                />
                <RecommendActivityInfo activity={activity} />
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </StyledWrapper>
  )
}

export default RecommendActivityMobile

const StyledWrapper = styled.div`
  position: relative;

  .activity-container {
    display: flex;
    flex-direction: column;
  }

  .loading {
    width: 100%;
    padding-top: 17%;
    @media (max-width: 1279.95px) {
      padding-top: 23.76%;
    }
  }
`
