import { FC, useState } from 'react'
import gql from 'graphql-tag'
import pick from 'lodash/pick'
import Image from 'next/image'
import Link from 'next/link'
import styled from 'styled-components'
import {
  ActivityListCardItem_SecondInfoText_ActivityFragment,
  ActivityScrap_CurrentUserFragment,
  RecommendActivity_S_Banner_ActivityFragment,
} from 'generated/graphql'
import Skeleton from '@app.components/Skeleton'
import {
  S_BANNER_PC_HEIGHT_SIZE,
  S_BANNER_PC_WIDTH_SIZE,
} from 'app.constants/recruitImageSize'
import ScrapBoxButton from 'components/ActivityListCardItem/ScrapBoxButton'
import SecondInfoText from 'components/ActivityListCardItem/SecondInfoText'
import { ActivityTypeID } from 'constants/enums'
import { optimizeImageUrl, imageLoader } from 'utils/optimizeImageUrl'

gql`
  fragment RecommendActivity_S_Banner_activity on Activity {
    ...ActivityListCardItem_activity
    advertisement(adType: $adType) {
      ...RecommendActivity_advertisement
    }
  }
`

interface IFS_BannerProps {
  activity?: RecommendActivity_S_Banner_ActivityFragment
  currentUser?: ActivityScrap_CurrentUserFragment
  loading: boolean
}

const S_Banner: FC<IFS_BannerProps> = ({ activity, currentUser, loading }) => {
  const { organizationName, advertisement: sBanner } = activity || {}

  const [showInfo, setShowInfo] = useState(false)

  const secondInfos = pick(activity, [
    'recruitCloseAt',
    'recruitType',
    'replyCount',
    'viewCount',
  ]) as ActivityListCardItem_SecondInfoText_ActivityFragment

  const handleClickBanner = () => {
    if (sBanner?.outLink) {
      window.open(sBanner?.outLink || '/')
    }
  }

  const getPCImage = () => {
    if (sBanner?.pcImage) {
      return sBanner?.pcImage
    }
    const isRecruit = activity?.type === ActivityTypeID.RECRUIT

    if (isRecruit) {
      return activity?.logoImage?.url || ''
    } else {
      return activity?.thumbnailImage?.url || ''
    }
  }

  return (
    <StyledWrapper>
      {(loading || !activity) && <Skeleton height="100%" width="100%" />}
      {!loading && (
        <div
          className="image-wrapper"
          onMouseEnter={() => setShowInfo(true)}
          onMouseLeave={() => setShowInfo(false)}
        >
          <Link href={`/activity/${activity?.id}`}>
            <a onClick={handleClickBanner}>
              <Image
                loader={imageLoader}
                className="thumbnail-image"
                key={`s-banner-${activity?.id}`}
                alt={sBanner?.title || ''}
                width={S_BANNER_PC_WIDTH_SIZE}
                height={S_BANNER_PC_HEIGHT_SIZE}
                src={optimizeImageUrl({
                  url: getPCImage(),
                  width: S_BANNER_PC_WIDTH_SIZE,
                  height: S_BANNER_PC_HEIGHT_SIZE,
                })}
              />
              {showInfo && (
                <div className="info-card">
                  <a onClick={handleClickBanner}>
                    <div className="info-title">
                      {sBanner?.title || activity?.title || '-'}
                    </div>
                  </a>
                  <div className="second-info-text">
                    <div className="organization-name">
                      {organizationName || '-'}
                    </div>
                    <SecondInfoText activityInfos={secondInfos} />
                  </div>
                </div>
              )}
            </a>
          </Link>
          <ScrapBoxButton activity={activity} currentUser={currentUser} />
        </div>
      )}
    </StyledWrapper>
  )
}

export default S_Banner

const StyledWrapper = styled.div`
  width: 100%;
  max-width: 552px;
  padding: 0 12px 50px 12px;

  .image-wrapper {
    position: relative;

    .thumbnail-image {
      border-radius: 5px;
      border: 1px solid #e8e8e8 !important;
    }
    .info-card {
      background-color: rgba(255, 255, 255, 1);
      position: absolute;
      bottom: 0;
      left: 0;
      height: 67px;
      width: 100%;
      padding: 10px;
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      .info-title {
        font-size: 16px;
        letter-spacing: -0.64px;
        font-weight: bold;
        margin-bottom: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .second-info-text {
        display: flex;
        justify-content: space-between;
        .organization-name {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
`
