import { useUA } from 'context'
import { NextPage } from 'next'
import { NextSeo } from 'next-seo'
import styled from 'styled-components'
import { GOOGLE_AD_MANAGER_MAP } from '@app.feature/googleAdManager/modules/constants'
import { GoogleAdManagerProvider } from '@app.feature/googleAdManager/provider/GoogleAdManagerProvider'
import ScreenHomeDesktop from '@app.feature/home/desktop/screen/ScreenHome.desktop'
import ScreenHomeMobile from '@app.feature/home/mobile/screen/ScreenHome.mobile'
import { NavBarActiveKey } from 'app.layout/constant/pageLayout.type'
import PageLayoutDesktop from 'app.layout/desktop/PageLayout.desktop'
import PageLayoutMobile from 'app.layout/mobile/PageLayout.mobile'
import LinkedChannelJsonLd from 'components/JsonLd/LinkedChannelJsonLd'
import useCanonical from 'hooks/useCanonical'

const MainHomePage: NextPage = () => {
  const { isMobile } = useUA()
  const canonical = useCanonical()

  return (
    <StyledWrapper>
      <NextSeo canonical={canonical} />
      <LinkedChannelJsonLd
        name="LINKareer"
        url="https://linkareer.com/"
        sameAs={[
          'https://blog.naver.com/linkareer1004',
          'https://post.naver.com/linkareer1004?isHome=1',
          'https://www.facebook.com/univwhat',
          'https://www.facebook.com/contestWhat',
        ]}
      />
      <GoogleAdManagerProvider
        ads={[
          ...GOOGLE_AD_MANAGER_MAP.MAIN_BANNER.PC,
          ...GOOGLE_AD_MANAGER_MAP.MAIN_BANNER.MO,
        ]}
        enableLazyLoad
        enableSingleRequest
      >
        {isMobile && (
          <PageLayoutMobile
            isMainLogoTitle={true}
            renderOptions={{
              Header: { searchBar: true },
              NavBar: { isSticky: true, activeKey: NavBarActiveKey.ROOT },
              Footer: true,
            }}
          >
            <ScreenHomeMobile />
          </PageLayoutMobile>
        )}
        {!isMobile && (
          <PageLayoutDesktop
            isMainLogoTitle={true}
            className="page-layout"
            renderOptions={{
              Header: { size: 'large', searchBar: true, externalNavBar: true },
              NavBar: { activeKey: NavBarActiveKey.ROOT },
              Footer: true,
            }}
          >
            <ScreenHomeDesktop />
          </PageLayoutDesktop>
        )}
      </GoogleAdManagerProvider>
    </StyledWrapper>
  )
}

export default MainHomePage

const StyledWrapper = styled.div`
  .page-layout {
    min-width: 1500px;
  }
`
