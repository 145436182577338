import { FC } from 'react'
import { useUA } from 'context'
import gql from 'graphql-tag'
import Image from 'next/image'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import { Advertisement } from 'generated/graphql'
import {
  HOT_LINE_BANNER_MOBILE_HEIGHT_SIZE,
  HOT_LINE_BANNER_MOBILE_WIDTH_SIZE,
  HOT_LINE_BANNER_PC_HEIGHT_SIZE,
  HOT_LINE_BANNER_PC_WIDTH_SIZE,
} from 'app.constants/recruitImageSize'
import { optimizeImageUrl, imageLoader } from 'utils/optimizeImageUrl'

gql`
  fragment HotLineItem_advertisment on Advertisement {
    id
    title
    pcImage
    mobileImage
    activityID
    outLink
  }
`

interface IFHotLineItemProps {
  advertisement: Advertisement
  loading: boolean
  totalSides: number
  activeSlide: number
}

const HotLineItem: FC<IFHotLineItemProps> = ({
  advertisement,
  totalSides,
  activeSlide,
}) => {
  const { isMobile } = useUA()
  const imageUrl = isMobile ? advertisement.mobileImage : advertisement.pcImage

  const handleClickBlankRoute = () => {
    if (advertisement.outLink) {
      window.open(advertisement.outLink, '_blank', 'noopener, noreferrer')
    }
  }

  const getImageSize = () => {
    if (isMobile) {
      return {
        width: HOT_LINE_BANNER_MOBILE_WIDTH_SIZE,
        height: HOT_LINE_BANNER_MOBILE_HEIGHT_SIZE,
      }
    } else
      return {
        width: HOT_LINE_BANNER_PC_WIDTH_SIZE,
        height: HOT_LINE_BANNER_PC_HEIGHT_SIZE,
      }
  }
  const activityUrl = advertisement.activityID
    ? `/activity/${advertisement.activityID}`
    : ''

  return (
    <StyledWrapper onClick={handleClickBlankRoute}>
      <Link href={activityUrl}>
        <a rel="nofollow sponsored">
          <mark className="custom-pagination">{`${activeSlide}/${totalSides}`}</mark>
          <Image
            layout="responsive"
            loader={imageLoader}
            key={`hot-line-banner-item-${advertisement.id}`}
            className="hotline-item-image"
            alt={advertisement?.title || ''}
            {...getImageSize()}
            src={optimizeImageUrl({
              url: imageUrl || '',
              ...getImageSize(),
            })}
          />
        </a>
      </Link>
    </StyledWrapper>
  )
}

export default HotLineItem

const StyledWrapper = styled.div`
  .hotline-item-image {
    border-radius: 5px;
    cursor: pointer;
  }

  .custom-pagination {
    position: absolute;
    z-index: 1;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 22px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 11px;
    padding: 2px 4px;
    right: 25px;
    bottom: 10px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    line-height: 2;
  }

  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding-left: 15px;
      padding-right: 15px;
      .custom-pagination {
        right: 8px;
      }
    `}
`
