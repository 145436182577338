import React from 'react'
import Image from 'next/image'
import styled from 'styled-components'
import {
  LINKAREER_MAGAZINE_HEIGHT_SIZE,
  LINKAREER_MAGAZINE_IMAGE_RENDER_HEIGHT,
  LINKAREER_MAGAZINE_IMAGE_RENDER_WIDTH,
  LINKAREER_MAGAZINE_WIDTH_SIZE,
} from 'app.constants/recruitImageSize'
import { imageLoader, optimizeImageUrl } from 'utils/optimizeImageUrl'

type TProps = {
  key: string
  imageUrl?: string | null | undefined
  title: string
  url: string
}

const LinkareerMagazineItem: React.FC<TProps> = ({ imageUrl, title, url }) => {
  return (
    <StyledWrapper>
      <a href={url}>
        <div className="card-wrapper">
          <Image
            loader={imageLoader}
            key={`magazine-item-${title}`}
            className="magazine-image"
            width={LINKAREER_MAGAZINE_IMAGE_RENDER_WIDTH}
            height={LINKAREER_MAGAZINE_IMAGE_RENDER_HEIGHT}
            alt={title}
            src={optimizeImageUrl({
              url: imageUrl || '',
              width: LINKAREER_MAGAZINE_WIDTH_SIZE,
              height: LINKAREER_MAGAZINE_HEIGHT_SIZE,
            })}
            objectFit="cover"
          />
          <div className="content-wrapper">
            <span className="linkareer-magazine-content">{title || '-'}</span>
          </div>
        </div>
      </a>
    </StyledWrapper>
  )
}

export default LinkareerMagazineItem

const StyledWrapper = styled.div`
  margin-bottom: 16px;
  max-height: 76px;

  .card-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .content-wrapper {
    padding-left: 16px;
    width: 199px;
  }

  .linkareer-magazine-content {
    font-size: 14px;
  }

  .magazine-image {
    border-radius: 5px;
    max-width: 100% !important;
    min-height: auto !important;
    height: auto !important;
  }
`
