import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const MentorReplyIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <desc>멘토 답변하기 아이콘</desc>
      <g data-name="그룹 32186">
        <g data-name="그룹 31930">
          <path fill="none" d="M0 0h16v16H0z" data-name="사각형 14962"></path>
          <g data-name="그룹 17818">
            <g data-name="그룹 15945">
              <g data-name="그룹 13819">
                <g fill="none" data-name="합치기 148">
                  <path d="M11.069 14.62l-.569-.721H4a3.5 3.5 0 01-3.5-3.5v-5.9A3.5 3.5 0 014 .999h8a3.5 3.5 0 013.5 3.5v5.9a3.5 3.5 0 01-2.037 3.181l-.825 1.045a1 1 0 01-1.57 0z"></path>
                  <path
                    fill="#333"
                    d="M11.853 13.677l.847-1.072.261-.12a2.309 2.309 0 001.34-2.09V4.499c0-1.268-1.033-2.3-2.3-2.3h-8a2.303 2.303 0 00-2.301 2.3v5.895c0 1.268 1.032 2.3 2.3 2.3h7.077l.776.983m0 1.323a.995.995 0 01-.784-.38l-.573-.726H4a3.5 3.5 0 01-3.5-3.5V4.499A3.5 3.5 0 014 .999h8a3.5 3.5 0 013.5 3.5v5.895a3.5 3.5 0 01-2.037 3.18l-.825 1.046a.995.995 0 01-.784.38z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
        <path
          fill="#01a0ff"
          d="M5.345 11.284a.9.9 0 01-.31-.16.606.606 0 01-.175-.23.663.663 0 01-.055-.26.785.785 0 01.04-.25l2.17-6.13a.9.9 0 01.3-.385 1.045 1.045 0 01.67-.185 1.067 1.067 0 01.68.185.89.89 0 01.3.385l2.17 6.15a.645.645 0 01-.01.56.733.733 0 01-.45.3.852.852 0 01-.635-.02.711.711 0 01-.355-.43l-.46-1.37h-2.42l-.48 1.42a.632.632 0 01-.35.37.947.947 0 01-.63.05zm2.67-5.44l-.81 2.39h1.61z"
          data-name="패스 11879"
        ></path>
      </g>
    </svg>
  )
}

export default MentorReplyIcon
