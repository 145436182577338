import { FC } from 'react'
import styled from 'styled-components'

const BestStoryEmpty: FC = () => {
  return (
    <StyledWrapper>
      <img src="/static/images/channel/recruit.png" alt="enterprise" />
      <span>베스트 소식이 없습니다.</span>
    </StyledWrapper>
  )
}

export default BestStoryEmpty

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding: 115px 0;
  font-size: 14px;
  color: #777;
  img {
    width: 67px;
    height: 50px;
    margin-bottom: 8px;
  }
`
