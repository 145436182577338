import { FC, useMemo, useState } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { BestNewsItemType, useGqlChannelBestNewsQuery } from 'generated/graphql'
import { replaceStrToDots } from '@app.feature/channel/channel.feed/module/ReplaceStrToDots'
import useIsWebview from '@app.modules/hooks/useIsWebview'
import { getWebviewHref } from '@app.modules/util'
import BestStoryList, { TBestStoryItem } from '../BestStoryList'

gql`
  query gqlChannelBestNews($pagination: Pagination, $type: BestNewsItemType) {
    bestNewsItems(pagination: $pagination, type: $type) {
      nodes {
        id
        content
        newsCommentCount
        channel {
          id
          url
          name
        }
      }
    }
  }
`

const ChannelBestNews: FC = () => {
  const isWebview = useIsWebview()
  const [currentTab, setCurrentTab] = useState(BestNewsItemType.RECENT)
  const { data, loading } = useGqlChannelBestNewsQuery({
    variables: {
      pagination: {
        page: 1,
        pageSize: 10,
      },
      type: currentTab,
    },
  })

  const NewsBestTabs = useMemo(() => {
    return [
      {
        label: '실시간',
        active: currentTab === BestNewsItemType.RECENT,
        onClick: () => setCurrentTab(BestNewsItemType.RECENT),
      },
      {
        label: '댓글순',
        active: currentTab === BestNewsItemType.COMMENT,
        onClick: () => setCurrentTab(BestNewsItemType.COMMENT),
      },
      {
        label: '스크랩순',
        active: currentTab === BestNewsItemType.SCRAP,
        onClick: () => setCurrentTab(BestNewsItemType.SCRAP),
      },
      {
        label: '좋아요순',
        active: currentTab === BestNewsItemType.LIKE,
        onClick: () => setCurrentTab(BestNewsItemType.LIKE),
      },
    ]
  }, [currentTab])

  const dataSet: TBestStoryItem[] = useMemo(() => {
    const result =
      data?.bestNewsItems?.nodes.map((newsItem) => {
        const channelNameEllipsis = replaceStrToDots(
          newsItem?.channel?.name || '',
          4,
        )
        // html 태그 제거 정규식
        const htmlTagRegex = /<[^>]*>?/g
        const escapedHtmlTag = newsItem?.content.replace(htmlTagRegex, '') || ''

        // "새고운 공고가 등록되었습니다.", "공고제목 :", "모집기간 :", "상세url :"이 모두 들어가는 경우 정규식
        const automaticNewsItemContentRegex =
          /새로운 공고가 등록되었습니다\.\s*공고제목\s*:\s*.+\s*모집기간\s*:\s*.+\s*상세url\s*:\s*.+/
        const isAutomaticNewsItem =
          automaticNewsItemContentRegex.test(escapedHtmlTag)

        // "공고제목 :" 이후부터 "모집기간 :"전까지의 문자열을 추출하는 정규식
        const escapePrefixRegex = /공고제목 : (.*?)모집기간 :/
        const escapedPrefix = escapedHtmlTag.match(escapePrefixRegex)?.[1] || ''

        return {
          href: getWebviewHref(
            `/channel/${newsItem?.channel?.url}/${newsItem?.id}`,
            isWebview,
          ),
          content: `[${channelNameEllipsis}] ${
            isAutomaticNewsItem ? escapedPrefix : escapedHtmlTag
          }`,
          commentCount: newsItem?.newsCommentCount || 0,
        }
      }) || []

    return result
  }, [data])

  return (
    <StyledWrapper>
      <div className="tab-box">
        {NewsBestTabs.map((tab) => (
          <button
            key={tab.label}
            className="tab"
            data-active={tab.active}
            onClick={tab.onClick}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <BestStoryList data={dataSet} loading={loading} />
    </StyledWrapper>
  )
}

export default ChannelBestNews

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0px;
  .tab-box {
    display: flex;
    gap: 16px;
    .tab {
      cursor: pointer;
      border: none;
      padding: 0px;
      background: none;
      font-size: 14px;
      font-weight: 500;
      color: #666;
      &[data-active='true'] {
        color: #01a0ff;
        font-weight: bold;
      }
      :hover {
        color: #01a0ff;
      }
    }
  }
`
