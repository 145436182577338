import { TJobCategories, TJobCategory } from '../../type/jobCategoryStoreType'

const getCategoriesByIds = (
  ids: string[],
  categories: TJobCategories,
): TJobCategory[] => {
  const result: TJobCategory[] = []

  ids.map((id) => {
    const targetCategory = categories?.[id]
    if (targetCategory) {
      result.push(targetCategory)
    }
  })

  result.sort((a, b) => a.seq - b.seq)

  return result
}

export default getCategoriesByIds
