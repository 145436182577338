import { FC } from 'react'
import styled from 'styled-components'
import { APP_STORE_URL, PLAY_STORE_URL } from 'app.constants/appInstallUrls'

const InstallAppBanner: FC = () => {
  return (
    <StyledWrapper>
      <div className="install-app-title">APP 설치하고</div>
      <div className="install-app-subtitle">
        스크랩한 공고의 <br />
        마감 알림을 받아보세요!
      </div>
      <img
        className="app-banner"
        src="static/images/app-banner/app-banner.png"
        alt=""
      />
      <a href={PLAY_STORE_URL} target="_blank" rel="noopener noreferrer">
        <img
          className="play-store-img"
          src="/static/images/app-banner/play-store-badge.png"
          alt="구글플레이"
        />
      </a>
      <a href={APP_STORE_URL} target="_blank" rel="noopener noreferrer">
        <img
          className="app-store-img"
          src="/static/images/app-banner/app-store-badge.png"
          alt="앱스토어"
        />
      </a>
    </StyledWrapper>
  )
}

export default InstallAppBanner

const StyledWrapper = styled.div`
  position: absolute;
  left: calc(100% + 20px);
  top: 0;
  width: 160px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  border-radius: 5px;
  background-color: #01a0ff;
  .install-app-title {
    padding-top: 28px;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 2px;
    text-align: center;
  }
  .install-app-subtitle {
    padding-top: 4px;
    font-size: 14px;
    text-align: center;
    margin-bottom: 14px;
  }
  .app-banner {
    margin-bottom: 12px;
    padding: 0 8px;
  }
  .play-store-img {
    width: 144px;
    height: 58px;
  }
  .app-store-img {
    width: 126px;
    height: 42px;
    margin-bottom: 14px;
  }
`
