import { FC } from 'react'
import Head from 'next/head'

export interface LinkedChannelJsonLdProps {
  url: string
  name: string
  sameAs: string[]
}

const LinkedChannelJsonLd: FC<LinkedChannelJsonLdProps> = ({
  url,
  name,
  sameAs,
}) => {
  const jslonld = `{
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "${name}",
    "url": "${url}",
    "sameAs": "${sameAs}"
  }`

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: jslonld }}
      />
    </Head>
  )
}

export default LinkedChannelJsonLd
