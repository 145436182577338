import { FC, Fragment } from 'react'
import times from 'lodash/times'
import ActivityListCardItem from 'components/ActivityListCardItem'

interface IFProps {
  time?: number
  className?: string
}

const ActivityLoadingItem: FC<IFProps> = ({ time = 1, className }) => {
  const loadingItems = times(time)

  return (
    <Fragment>
      {loadingItems.map((item) => {
        return (
          <div key={`PopularActivityLoading-${item}`} className={className}>
            <ActivityListCardItem isLoading />
          </div>
        )
      })}
    </Fragment>
  )
}

export default ActivityLoadingItem
