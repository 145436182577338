import { FC } from 'react'
import gql from 'graphql-tag'
import Image from 'next/image'
import styled from 'styled-components'
import {
  RecommendActivityItem_ActivityFragment,
  ActivityScrap_CurrentUserFragment,
} from 'generated/graphql'
import Skeleton from '@app.components/Skeleton'
import { S_BANNER_MOBILE_WIDTH_SIZE } from 'app.constants/recruitImageSize'
import ScrapBoxButton from 'components/ActivityListCardItem/ScrapBoxButton'
import Link from 'components/Link'
import { ActivityTypeID } from 'constants/enums'
import { optimizeImageUrl, imageLoader } from 'utils/optimizeImageUrl'

gql`
  fragment RecommendActivityItem_activity on Activity {
    ...ActivityListCardItem_activity
    advertisement(adType: $adType) {
      activityID
      ...RecommendActivity_advertisement
    }
  }
`

interface IFRecommendActivityItemProps {
  activity?: RecommendActivityItem_ActivityFragment
  currentUser?: ActivityScrap_CurrentUserFragment
  loading?: boolean
  dragging?: boolean
  totalSides?: number
  activeSlide?: number
}

const RecommendActivityItem: FC<IFRecommendActivityItemProps> = ({
  activity,
  currentUser,
  dragging,
  loading,
  totalSides,
  activeSlide,
}) => {
  const { advertisement } = activity || {}

  const linkProps = {
    href: `/activity/[id]`,
    as: `/activity/${activity?.id}`,
    naked: true,
    ...(advertisement?.outLink && {
      onClick: () => {
        if (dragging) return false
        window.open(advertisement?.outLink || '/')
      },
    }),
  }

  const title = advertisement?.title || activity?.title || '-'

  const getMobileImage = () => {
    if (advertisement?.mobileImage) return advertisement?.mobileImage
    const isRecruit = activity?.type === ActivityTypeID.RECRUIT

    return (
      (isRecruit ? activity?.logoImage?.url : activity?.thumbnailImage?.url) ||
      ''
    )
  }

  return (
    <StyledWrapper>
      {loading ? (
        <Skeleton className="skeleton-activity-item" />
      ) : (
        <>
          <Link {...linkProps}>
            <div className="img-wrapper">
              <Image
                loader={imageLoader}
                className="activity-image"
                alt={title}
                src={optimizeImageUrl({
                  url: getMobileImage(),
                  width: S_BANNER_MOBILE_WIDTH_SIZE,
                  height: 'auto',
                })}
                layout="fill"
                objectFit="cover"
                objectPosition="top"
              />
            </div>
          </Link>
          <div className="custom-pagination">
            {`${activeSlide}/${totalSides}`}
          </div>
          <ScrapBoxButton
            activity={activity}
            className="scrap-button"
            currentUser={currentUser}
          />
        </>
      )}
    </StyledWrapper>
  )
}

export default RecommendActivityItem

const StyledWrapper = styled.div`
  position: relative;
  height: 100%;
  @media (max-width: 599.95px) {
    padding: 0px;
  }

  .scrap-button {
    z-index: 10;
    top: 8px;
    right: 25px;
    padding: 3px 8px;
    @media (max-width: 599.95px) {
      right: 8px;
    }
  }

  .img-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-top: 58%;
  }

  .activity-image {
    width: 100% !important;
    height: auto !important;
    min-height: auto !important;
    margin: 0 !important;
  }

  .skeleton-activity-item {
    padding-top: 58%;
  }

  .custom-pagination {
    position: absolute;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 22px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 11px;
    padding: 2px 4px;
    right: 25px;
    bottom: 8px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    line-height: 2;
    @media (max-width: 599.95px) {
      right: 8px;
    }
  }
`
