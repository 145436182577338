import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import Image from 'next/image'
import styled, { css } from 'styled-components'
import { MarkdownViewer } from 'components/MarkdownViewer'
import { MARKETING_SMS } from 'constants/marketing/sms'
import ExpandMoreIcon from 'svgIcons/ExpandMore'

const SubscribeSMSDesktop = () => {
  const { register } = useFormContext()
  const [isPolicyOpen, setIsPolicyOpen] = useState(false)

  const handleDetailClick = () => {
    setIsPolicyOpen(!isPolicyOpen)
  }

  return (
    <StyledWrapper>
      <div className="checkbox-container">
        <input
          type="checkbox"
          id={`receive-sms`}
          {...register('subscribeSMS')}
        />
        <label htmlFor={`receive-sms`} className="pointer">
          <i className="check-svg">
            <Image
              src={`/static/images/signup/ic-check-white.svg`}
              width={13}
              height={10}
              alt="checked"
            />
          </i>
        </label>
        <p className="text-body">
          <span className="optional-sms">(선택) </span>
          <span className="text">마케팅 정보 수신 동의(SMS/MMS)</span>
        </p>
        <div className="policy-arrow-container" onClick={handleDetailClick}>
          <p>{isPolicyOpen ? '내용닫기' : '내용보기'}</p>
          <i className={`arrow-wrapper ${isPolicyOpen && 'opened'}`}>
            <ExpandMoreIcon />
          </i>
        </div>
      </div>
      <span className={'receive-message'}>
        *수신 동의해야 관심사에 대한 정보를 문자로 바로 받을 수 있어요
      </span>
      <div className={`policy-scroll-container ${!isPolicyOpen && 'hidden'}`}>
        <MarkdownViewer content={MARKETING_SMS.slice(34)} />
      </div>
    </StyledWrapper>
  )
}
export default SubscribeSMSDesktop

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    .checkbox-container {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.86;
      letter-spacing: -0.56px;
      > input[type='checkbox'] {
        display: none;
        &:checked + label {
          background-color: ${theme.color.primary_2};
          border: 0;
          > .check-svg {
            display: flex;
            border-radius: 8px;
          }
        }
      }
      > label {
        width: 22px;
        height: 22px;
        border-radius: 5px;
        background: white;
        border: 1px solid ${theme.line.line_2};
        .check-svg {
          width: 22px;
          height: 22px;
          display: none;
          align-items: center;
          justify-content: center;
        }
      }
      display: flex;
      align-items: center;
      gap: 9px;
      .text-body {
        flex: 1;
        > .optional-sms {
          color: ${theme.color.text_3};
        }
      }
    }
    > .receive-message {
      font-size: 12px;
      letter-spacing: -0.48px;
      color: ${theme.color.primary_2};
    }
    .policy-arrow-container {
      display: flex;
      gap: 3px;
      cursor: pointer;
      .arrow-wrapper {
        display: flex;
        align-items: center;
      }
      .opened {
        rotate: 180deg;

        transition-duration: 500ms;
        transition-property: rotate;
      }
    }
    .policy-scroll-container {
      height: 137px;
      overflow-y: scroll;
      border: 1px solid #e3e3e3;
      padding: 0 15px;
      margin-top: 10px;
    }
    .hidden {
      display: none;
    }
  `}
`
