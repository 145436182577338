import { FC } from 'react'
import styled from 'styled-components'
import ActivityLoadingItem from '../../ActivityLoadingItem/ActivityLoadingItem'

const PopularRecruitLoadingDesktop: FC = () => {
  return (
    <StyledWrapper>
      <h2 className="title-text">실시간 인기 채용</h2>
      <div className="loading-wrapper">
        <ActivityLoadingItem time={4} className="loading-item" />
      </div>
    </StyledWrapper>
  )
}

export default PopularRecruitLoadingDesktop

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .title-text {
    font-size: 20px;
    font-weight: bold;
  }
  .loading-wrapper {
    display: flex;
    gap: 24px;
    .loading-item {
      width: 100%;
    }
  }
`
