import { FC } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import {
  AdType,
  AdStatus,
  useGqlPopularRecruitMobileQuery,
  PopularRecruitMobile_CurrentUserFragment,
} from 'generated/graphql'
import ActivityListCardItem from 'components/ActivityListCardItem'
import ActivityLoadingItem from '../../ActivityLoadingItem/ActivityLoadingItem'

gql`
  fragment PopularRecruitMobile_currentUser on User {
    ...ActivityScrap_currentUser
  }

  fragment PopularRecruitMobile_advertisement on Advertisement {
    id
    title
    outLink
    mobileImage
  }

  query gqlPopularRecruitMobile(
    $adType: AdType!
    $status: AdStatus
    $withManager: Boolean!
  ) {
    advertisementList(filterBy: { adType: $adType, status: $status }) {
      activities {
        ...ActivityListCardItem_activity
        advertisement(adType: $adType) {
          ...PopularRecruitMobile_advertisement
        }
      }
    }
  }
`

interface IFPopularRecruitMobileProps {
  currentUser?: PopularRecruitMobile_CurrentUserFragment
  loading?: boolean
}

const PopularRecruitMobile: FC<IFPopularRecruitMobileProps> = ({
  currentUser,
  loading: currentUserLoading,
}) => {
  const { data, loading } = useGqlPopularRecruitMobileQuery({
    variables: {
      adType: AdType.LIVE_POPULAR_RECRUIT,
      status: AdStatus.OPEN,
      withManager: false,
    },
  })
  const activities = data?.advertisementList?.activities || []
  const isLoading = currentUserLoading || loading

  return (
    <StyledWrapper>
      <h2 className="title-text">실시간 인기 채용</h2>
      {isLoading && (
        <div className="loading-wrapper">
          <ActivityLoadingItem time={2} className="loading-item" />
        </div>
      )}
      {!isLoading && (
        <div className="card-list">
          {activities?.map((activity) => {
            const linkProps = {
              href: '/activity/[id]',
              as: `/activity/${activity?.id}`,
              naked: true,
              ...(activity?.advertisement?.outLink && {
                onClick: () => {
                  window.open(activity?.advertisement?.outLink || '/')
                },
              }),
            }

            return (
              <div key={activity.id} className="card-item">
                <ActivityListCardItem
                  activity={activity}
                  cardTitle={activity?.advertisement?.title}
                  isLoading={loading}
                  currentUser={currentUser}
                  managerBadgeOn={false}
                  secondinfoTextProps={{
                    closeDateHighLightClassName: 'close-date-highlight',
                  }}
                  cardImageProps={{
                    width: 155,
                    src: activity?.advertisement?.mobileImage || '',
                    alt: activity?.advertisement?.title || '',
                  }}
                  linkProps={linkProps}
                />
              </div>
            )
          })}
        </div>
      )}
    </StyledWrapper>
  )
}

export default PopularRecruitMobile

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0 20px 20px;
  overflow: hidden;
  background-color: #fff;
  border-bottom: 15px solid #fafafa;

  .loading-wrapper {
    display: flex;
    gap: 10px;
    padding-right: 20px;
    .loading-item {
      width: 100%;
    }
  }
  .title-text {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .card-list {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    padding-right: 20px;
    ::-webkit-scrollbar {
      display: none;
    }
    .card-item {
      width: calc(50% - 5px);
      flex-shrink: 0;
      .close-date-highlight {
        color: #01a0ff;
      }
    }
  }
`
