import { TJobCategoryState } from '../../type/jobCategoryStoreType'

const activeId = (
  id: string,
  jobCategoryState: TJobCategoryState,
): Partial<TJobCategoryState> => {
  const targetCategory = jobCategoryState.categories[id]

  if (!targetCategory) {
    console.error('activeId function error. not found category')
    return jobCategoryState
  }

  const { parentId } = targetCategory

  const result = jobCategoryState

  result.activeIds = parentId ? [parentId, id] : [id]
  return result
}

export default activeId
