import { FC } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import {
  AdType,
  AdStatus,
  useGqlPopularRecruitDesktopQuery,
  PopularRecruitDesktop_CurrentUserFragment,
} from 'generated/graphql'
import ActivityListCardItem from 'components/ActivityListCardItem'
import ActivityLoadingItem from '../../ActivityLoadingItem/ActivityLoadingItem'

gql`
  fragment PopularRecruitDesktop_currentUser on User {
    ...ActivityScrap_currentUser
  }

  fragment PopularRecruitDesktop_advertisement on Advertisement {
    id
    title
    outLink
    pcImage
    mobileImage
  }

  query gqlPopularRecruitDesktop(
    $adType: AdType!
    $status: AdStatus
    $withManager: Boolean!
  ) {
    advertisementList(filterBy: { adType: $adType, status: $status }) {
      activities {
        ...ActivityListCardItem_activity
        advertisement(adType: $adType) {
          ...PopularRecruitDesktop_advertisement
        }
      }
    }
  }
`

interface IFPopularRecruitDesktopProps {
  currentUser?: PopularRecruitDesktop_CurrentUserFragment
  loading?: boolean
}

const PopularRecruitDesktop: FC<IFPopularRecruitDesktopProps> = ({
  currentUser,
  loading: currentUserLoading,
}) => {
  const { data, loading } = useGqlPopularRecruitDesktopQuery({
    variables: {
      adType: AdType.LIVE_POPULAR_RECRUIT,
      status: AdStatus.OPEN,
      withManager: false,
    },
  })
  const activities = data?.advertisementList?.activities || []
  const isLoading = currentUserLoading || loading

  return (
    <StyledWrapper>
      <h2 className="title-text">실시간 인기 채용</h2>
      {isLoading && (
        <div className="loading-wrapper">
          <ActivityLoadingItem time={4} className="loading-item" />
        </div>
      )}
      {!isLoading && (
        <div className="activity-list">
          {activities.map((activity) => {
            const linkProps = {
              href: '/activity/[id]',
              as: `/activity/${activity?.id}`,
              naked: true,
              ...(activity?.advertisement?.outLink && {
                onClick: () => {
                  window.open(activity?.advertisement?.outLink || '/')
                },
              }),
            }

            return (
              <div key={activity.id} className="activity-item">
                <ActivityListCardItem
                  activity={activity}
                  cardTitle={activity?.advertisement?.title}
                  isLoading={loading}
                  currentUser={currentUser}
                  managerBadgeOn={false}
                  secondinfoTextProps={{
                    closeDateHighLightClassName: 'close-date-highlight',
                  }}
                  cardImageProps={{
                    width: 250,
                    src: activity?.advertisement?.pcImage || '',
                    alt: activity?.advertisement?.title || '',
                  }}
                  linkProps={linkProps}
                />
              </div>
            )
          })}
        </div>
      )}
    </StyledWrapper>
  )
}

export default PopularRecruitDesktop

const StyledWrapper = styled.div`
  .title-text {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .loading-wrapper {
    display: flex;
    gap: 28px;
    .loading-item {
      width: 100%;
    }
  }
  .activity-list {
    display: flex;
    gap: 28px;
    .activity-item {
      width: 100%;
      max-width: 254px;
      .close-date-highlight {
        color: #01a0ff;
      }
    }
  }
`
