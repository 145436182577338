import { uniq } from 'lodash'
import { TJobCategories } from '../../type/jobCategoryStoreType'

type TActionType = 'select' | 'unSelect'

interface IFDoCategoriesSelectChangeParams {
  targetCategoryId: string
  action: TActionType
  categories: TJobCategories
}

const doCategoriesSelectChange = (
  params: IFDoCategoriesSelectChangeParams,
): TJobCategories => {
  const { targetCategoryId, action, categories } = params
  const actionBoolean = action === 'select'
  let targetCategory = categories[targetCategoryId]

  while (targetCategory) {
    if (!(!actionBoolean && targetCategory.selectedChildrenIds.length !== 0)) {
      targetCategory.isSelect = actionBoolean
    }
    if (targetCategory.parentId) {
      const nextTargetCategory = categories[targetCategory.parentId]
      if (actionBoolean) {
        const newSelectedChildrenIds = uniq([
          ...nextTargetCategory.selectedChildrenIds,
          targetCategory.id,
        ])

        nextTargetCategory.selectedChildrenIds = newSelectedChildrenIds
      }
      if (!actionBoolean && targetCategory.selectedChildrenIds.length === 0) {
        nextTargetCategory.selectedChildrenIds =
          nextTargetCategory.selectedChildrenIds.filter(
            (id) => id !== targetCategory.id,
          )
      }
      targetCategory = nextTargetCategory
      continue
    }
    break
  }

  return categories
}

export default doCategoriesSelectChange
