import React from 'react'
import styled from 'styled-components'
import Skeleton from '@app.components/Skeleton'

const LinkareerMagazineLoadingItem: React.FC = () => {
  return (
    <StyledWrapper>
      <div className="left-skeleton">
        <Skeleton width={140} height={76} />
      </div>
      <div className="right-skeleton">
        <Skeleton width={199} height={76} />
      </div>
    </StyledWrapper>
  )
}

export default LinkareerMagazineLoadingItem

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  .right-skeleton {
    margin-left: 16px;
  }
`
