import { TJobCategoryState } from '../../type/jobCategoryStoreType'
import doCategoriesSelectChange from './doCategoriesSelectChange'

const unSelectId = (
  id: string,
  jobCategoryState: TJobCategoryState,
): Partial<TJobCategoryState> => {
  const targetCategory = jobCategoryState.categories[id]

  if (!targetCategory) {
    console.error('unSelectId function error. not found category')
    return jobCategoryState
  }

  const categories = doCategoriesSelectChange({
    targetCategoryId: id,
    action: 'unSelect',
    categories: jobCategoryState.categories,
  })

  const showSelectedIds = jobCategoryState.showSelectedIds.filter(
    (selectedId) => selectedId !== id,
  )

  const result = {
    ...jobCategoryState,
    categories,
    showSelectedIds,
  }

  return result
}

export default unSelectId
