import { FC } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { AD_INQUIRY_FORM_URL } from 'app.constants/adInquiry'
import { useOpenCustomerMessageForm } from 'components/customer-message-form'
import { MEDIA_INTRODUCTION } from 'constants/urls'
import Blah from 'svgIcons/Blah'
import GiveCard from 'svgIcons/GiveCard'
import PenAndCard from 'svgIcons/PenAndCard'
import StackofCard from 'svgIcons/StackofCard'

const RightBanner: FC = () => {
  const openCustomerMessageForm = useOpenCustomerMessageForm()

  return (
    <StyledWrapper>
      <a
        href={AD_INQUIRY_FORM_URL}
        target="_blank"
        rel="noreferrer"
        className="banner-item"
      >
        <GiveCard className="icon" />
        <div className="banner-title">광고문의</div>
      </a>
      <div className="column-hr" />
      <a href={MEDIA_INTRODUCTION} className="banner-item">
        <StackofCard className="icon" />
        <div className="banner-title">상품안내</div>
      </a>
      <div className="row-hr" />
      <div className="row-hr" />
      <Link href="/manage/welcome">
        <a className="banner-item">
          <PenAndCard className="icon" />
          <div className="banner-title">무료등록</div>
        </a>
      </Link>
      <div className="column-hr" />
      <button className="banner-item" onClick={openCustomerMessageForm}>
        <Blah className="icon" />
        <div className="banner-title">고객문의</div>
      </button>
    </StyledWrapper>
  )
}

export default RightBanner

const StyledWrapper = styled.div`
  position: absolute;
  top: 358px;
  left: calc(100% + 20px);
  width: 160px;
  height: 160px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-items: center;
  align-content: space-between;
  align-items: center;
  padding: 12px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  .banner-item {
    cursor: pointer;
    background: none;
    border: none;
    width: 62px;
    height: 62px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    gap: 5px;
    .icon {
      width: 26px;
      height: 23px;
      display: inline-block;
    }
    .banner-title {
      font-size: 12px;
    }
  }
  .row-hr {
    width: 48px;
    height: 1px;
    background-color: #e8e8e8;
    margin-left: 7px;
    margin-right: 7px;
  }
  .column-hr {
    width: 1px;
    height: 48px;
    background-color: #e8e8e8;
  }
`
