import { FC } from 'react'
import styled from 'styled-components'

const BestStoryLoading: FC = () => {
  return (
    <StyledWrapper>
      {new Array(10).fill('').map((_, index) => (
        <div key={index} className="loading" />
      ))}
    </StyledWrapper>
  )
}

export default BestStoryLoading

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  gap: 10px;
  .loading {
    background-color: #eee;
    border-radius: 5px;
    height: 14px;
  }
`
