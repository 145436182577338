import { FC } from 'react'
import { Grid, Typography } from '@material-ui/core'
import times from 'lodash/times'
import styled from 'styled-components'
import Skeleton from '@app.components/Skeleton'
import ActivityListCardItem from 'components/ActivityListCardItem'

const RecommendActivityListLoading: FC = () => {
  return (
    <StyledWrapper>
      <Typography className="loading-title">링커리어 추천활동</Typography>
      <Grid container spacing={3}>
        <Grid item className="grid-item" md={6}>
          <Skeleton height="100%" width="100%" className="skeleton-img" />
        </Grid>
        {times(6).map((item) => {
          return (
            <Grid
              item
              className="grid-item"
              md={3}
              key={`RecomendActivityList-loading-${item}`}
            >
              <ActivityListCardItem isLoading />
            </Grid>
          )
        })}
      </Grid>
    </StyledWrapper>
  )
}

export default RecommendActivityListLoading

const StyledWrapper = styled.div`
  .loading-title {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.96px;
    padding-bottom: 30px;
  }
  .skelton-img {
    border-radius: 5px;
  }
  .grid-item {
    padding: 0 12px 50px 12px !important;
  }
`
