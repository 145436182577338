import { FC, useRef } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import SwiperCore, { Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  AdType,
  AdvertisementOrderField,
  OrderDirection,
  AdStatus,
  ActivityListCardItem_CurrentUserFragment,
  useGqlPopularActivityDesktopQuery,
} from 'generated/graphql'
import ActivityListCardItem from 'components/ActivityListCardItem'
import ArrowLeft from 'svgIcons/ArrowLeft'
import ArrowRight from 'svgIcons/ArrowRight'
import ActivityLoadingItem from '../../ActivityLoadingItem/ActivityLoadingItem'

gql`
  fragment PopularActivityDesktop_currentUser on User {
    ...ActivityListCardItem_currentUser
  }

  fragment PopularActivityDesktop_advertisement on Advertisement {
    id
    title
    outLink
    pcImage
    mobileImage
  }

  query gqlPopularActivityDesktop(
    $adType: AdType!
    $status: AdStatus
    $orderBy: AdvertisementOrder
    $withManager: Boolean!
  ) {
    advertisementList(
      filterBy: { adType: $adType, status: $status }
      orderBy: $orderBy
    ) {
      activities {
        ...ActivityListCardItem_activity
        advertisement(adType: $adType) {
          ...PopularActivityDesktop_advertisement
        }
      }
      totalCount
    }
  }
`

interface IFPopularActivityDesktopProps {
  currentUser?: ActivityListCardItem_CurrentUserFragment
  loading?: boolean
}

const PopularActivityDesktop: FC<IFPopularActivityDesktopProps> = ({
  currentUser,
  loading: currentUserLoading,
}) => {
  SwiperCore.use([Autoplay, Navigation])
  const prevButtonRef = useRef<HTMLButtonElement>(null)
  const nextButtonRef = useRef<HTMLButtonElement>(null)

  const { data, loading } = useGqlPopularActivityDesktopQuery({
    variables: {
      adType: AdType.POPULAR_ACTIVITY,
      status: AdStatus.OPEN,
      orderBy: {
        field: AdvertisementOrderField.SEQ,
        direction: OrderDirection.ASC,
      },
      withManager: false,
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const activities = data?.advertisementList?.activities || []
  const isLoading = currentUserLoading || loading

  return (
    <StyledWrapper>
      <section className="title-section">
        <h2 className="title-text">금주의 인기공고</h2>
        <article className="button-article">
          <button ref={prevButtonRef}>
            <ArrowLeft />
          </button>
          <button ref={nextButtonRef}>
            <ArrowRight />
          </button>
        </article>
      </section>
      {isLoading && (
        <div className="loading-wrapper">
          <ActivityLoadingItem time={6} className="loading-item" />
        </div>
      )}
      {!isLoading && (
        <Swiper
          modules={[Autoplay, Navigation]}
          loop
          slidesPerView={6}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          navigation={{
            prevEl: prevButtonRef.current,
            nextEl: nextButtonRef.current,
          }}
        >
          {activities.concat(activities).map((activity, index) => (
            <SwiperSlide
              key={`popular-${activity.id}-${index}`}
              className="slide-item"
            >
              <ActivityListCardItem
                managerBadgeOn={false}
                activity={activity}
                cardTitle={activity?.advertisement?.title}
                isLoading={loading}
                currentUser={currentUser}
                cardImageProps={{
                  width: 155,
                  src: activity?.advertisement?.pcImage || '',
                  alt: activity?.advertisement?.title || '',
                }}
                {...(activity?.advertisement?.outLink && {
                  linkProps: {
                    onClick: () =>
                      window.open(activity?.advertisement?.outLink || '/'),
                  },
                })}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </StyledWrapper>
  )
}

export default PopularActivityDesktop

const StyledWrapper = styled.div`
  border-radius: 5px;
  padding: 30px 0 40px 30px;
  background-color: #fafafa;
  .title-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
    margin-bottom: 15px;
    .title-text {
      font-size: 20px;
      font-weight: bold;
    }
    .button-article {
      display: flex;
      gap: 5px;
      button {
        padding: 8px 12px;
        border-radius: 3px;
        border: 1px solid #e8e8e8;
        background-color: #fff;
        cursor: pointer;
        svg {
          width: 6px;
          height: 10px;
        }
      }
    }
  }
  .loading-wrapper {
    display: flex;
    gap: 24px;
    .loading-item {
      width: 100%;
    }
  }
  .swiper-wrapper {
    display: flex;
    gap: 24px;
    .slide-item {
      max-width: 155px;
    }
  }
`
