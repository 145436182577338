import { TStoreSlice } from '@app.feature/jobCategory/type/jobCategoryStoreType'
import { selectId, unSelectId, unSelectIdWithChildren } from '../module'
import getSelectedCategoriesIncludeParent from '../module/getSelectedCategoriesIncludeParent'

export type TSelectSlice = {
  showSelectedIds: string[]
  selectId: (id: string) => void
  unSelectId: (id: string) => void
  unSelectIdWithChildren: (ids: string) => void
  idIsShowSelected: (id: string) => boolean
  getSelectedCategoriesIncludeParent: () => string[]
}

const createSelectSlice: TStoreSlice<TSelectSlice> = (set, get) => ({
  showSelectedIds: [],
  selectId: (id) => {
    const currentJobCategoryState = get()
    const updatedState = selectId(id, currentJobCategoryState)

    set((state) => ({
      ...state,
      ...updatedState,
      orderMap: {
        ...state.orderMap,
        [id]: state.order,
      },
      order: state.order + 1,
    }))
  },
  unSelectId: (id) => {
    const currentJobCategoryState = get()
    const updatedState = unSelectId(id, currentJobCategoryState)

    set((state) => ({
      ...state,
      ...updatedState,
    }))
  },
  unSelectIdWithChildren: (id) => {
    const currentJobCategoryState = get()
    const updatedState = unSelectIdWithChildren(id, currentJobCategoryState)

    set((state) => ({
      ...state,
      ...updatedState,
    }))
  },
  idIsShowSelected: (id) => {
    const currentJobCategoryState = get()
    return (
      currentJobCategoryState.showSelectedIds.findIndex(
        (selectId) => selectId === id,
      ) !== -1
    )
  },
  getSelectedCategoriesIncludeParent: () => {
    const currentJobCategoryState = get()
    const result = getSelectedCategoriesIncludeParent(currentJobCategoryState)

    return result
  },
})

export default createSelectSlice
