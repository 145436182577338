import { FC } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import {
  LinkareerMagazine_LinkareerMagazineFragment,
  useGqlLinkareerMagazineListQuery,
} from 'generated/graphql'
import LinkareerMagazineItem from './LinkareerMagazineItem'
import LinkareerMagazineLoading from './LinkareerMagazineLoading'

gql`
  fragment LinkareerMagazine_LinkareerMagazine on LinkareerMagazine {
    id
    title
    url
    pcImageFile {
      id
      url
    }
  }

  query gqlLinkareerMagazineList {
    linkareerMagazineList {
      totalCount
      linkareerMagazines {
        ...LinkareerMagazine_LinkareerMagazine
      }
    }
  }
`

const LinkareerMagazine: FC = () => {
  const { data, loading, error } = useGqlLinkareerMagazineListQuery()
  const firstThreeMagazines =
    data?.linkareerMagazineList?.linkareerMagazines.slice(0, 3) || []
  const emptySlots = new Array(3 - firstThreeMagazines.length).fill(null)

  return !loading && !error && data ? (
    <StyledWrapper>
      <p className="linkareer-magazine-title">합격자료</p>
      {firstThreeMagazines.map(
        (item: LinkareerMagazine_LinkareerMagazineFragment) => {
          return (
            <LinkareerMagazineItem
              key={item.id}
              imageUrl={item.pcImageFile?.url || ''}
              title={item.title}
              url={item.url}
            />
          )
        },
      )}
      {emptySlots.map((_, index) => (
        <div className="empty-content" key={`placeholder_${index}`} />
      ))}
    </StyledWrapper>
  ) : (
    <LinkareerMagazineLoading />
  )
}

export default LinkareerMagazine

const StyledWrapper = styled.div`
  margin-bottom: 18px;
  .linkareer-magazine-title {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.8px;
    margin-bottom: 15px;
    cursor: default;
  }
  .empty-content {
    width: 355px;
    height: 78px;
    margin-bottom: 15px;
  }
`
