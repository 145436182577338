import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const ApproveMentorIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <desc>멘토 인증 뱃지</desc>
      <defs>
        <clipPath id="9l7iyq9gca">
          <path
            fill="#01a0ff"
            stroke="#707070"
            d="M0 0h14v14H0z"
            data-name="사각형 14266"
            transform="translate(0 .302)"
          ></path>
        </clipPath>
      </defs>
      <path fill="none" d="M0 0h16v16H0z" data-name="사각형 14267"></path>
      <g data-name="그룹 16175">
        <g data-name="그룹 17162">
          <g
            clipPath="url(#9l7iyq9gca)"
            data-name="그룹 16172"
            transform="translate(1 .698)"
          >
            <path
              fill="#01a0ff"
              stroke="#01a0ff"
              strokeWidth="1.2"
              d="M13.033 6.232a1.3 1.3 0 01-.339-1.22l.01-.041a1.3 1.3 0 00-.918-1.557 1.3 1.3 0 01-.9-.887l-.012-.041a1.3 1.3 0 00-1.58-.893 1.3 1.3 0 01-1.223-.311l-.031-.03a1.3 1.3 0 00-1.808.017 1.3 1.3 0 01-1.219.339l-.041-.01a1.3 1.3 0 00-1.557.918 1.3 1.3 0 01-.887.9l-.041.012a1.3 1.3 0 00-.893 1.577 1.3 1.3 0 01-.312 1.226l-.029.031a1.3 1.3 0 00.017 1.807 1.3 1.3 0 01.339 1.224l-.015.037a1.3 1.3 0 00.918 1.557 1.3 1.3 0 01.9.887l.012.041a1.3 1.3 0 001.574.889 1.3 1.3 0 011.226.316l.031.029a1.3 1.3 0 001.808-.017 1.3 1.3 0 011.231-.339l.041.01a1.3 1.3 0 001.557-.918 1.3 1.3 0 01.887-.9l.041-.012a1.3 1.3 0 00.884-1.58 1.3 1.3 0 01.316-1.226l.029-.031a1.3 1.3 0 00-.017-1.808"
              data-name="패스 8294"
            ></path>
          </g>
          <path
            fill="#01a0ff"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
            d="M4.98 8.285l2.494 2.172 3.544-4.963"
            data-name="패스 8303"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default ApproveMentorIcon
