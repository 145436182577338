import { FC } from 'react'
import pick from 'lodash/pick'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import {
  RecommendActivityItem_ActivityFragment,
  ActivityListCardItem_SecondInfoText_ActivityFragment,
} from 'generated/graphql'
import SecondInfoText from 'components/ActivityListCardItem/SecondInfoText'
import Link from 'components/Link'

interface IFRecommendActivityInfoProps {
  activity?: RecommendActivityItem_ActivityFragment
}

const RecommendActivityInfo: FC<IFRecommendActivityInfoProps> = ({
  activity,
}) => {
  const router = useRouter()
  const { advertisement } = activity || {}
  const secondInfos = pick(activity, [
    'recruitCloseAt',
    'recruitType',
    'replyCount',
    'viewCount',
  ]) as ActivityListCardItem_SecondInfoText_ActivityFragment

  const title = advertisement?.title || activity?.title || '-'
  const linkProps = {
    href: `/activity/[id]`,
    as: `/activity/${activity?.id}`,
    naked: true,
    ...(advertisement?.outLink && {
      onClick: () => {
        window.open(advertisement?.outLink || '/')
      },
    }),
  }

  const handleClickOrganization = () => {
    if (activity?.manager?.channel) {
      router.push(`/channel/${activity.manager.channel.url}`)
    }
  }

  return (
    <StyledWrapper hasChannel={!!activity?.manager?.channel}>
      <div className="second-info-wrapper">
        <Link {...linkProps}>
          <p className="second-info-title">{title}</p>
        </Link>
        <div className="orgname-second-info-wrapper">
          <p className="organization-name" onClick={handleClickOrganization}>
            {activity?.organizationName || '-'}
          </p>
          <SecondInfoText activityInfos={secondInfos} />
        </div>
      </div>
    </StyledWrapper>
  )
}

export default RecommendActivityInfo

type TStyleProps = {
  hasChannel: boolean
}

const StyledWrapper = styled.div<TStyleProps>`
  @media (max-width: 599.95px) {
    padding: 0;
  }

  .second-info-wrapper {
    top: 0;
    left: 0;
    background-color: white;
    padding: 15px 20px 16px 20px;
  }

  .orgname-second-info-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .org-name {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.66;
    letter-spacing: -0.6px;
  }

  .second-info-title {
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: -0.64px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
  }
  .organization-name {
    cursor: ${({ hasChannel }) => (hasChannel ? 'pointer' : 'default')};
    font-size: 12px;
    line-height: 30px;
    letter-spacing: -0.48px;
    text-rendering: auto;
  }
`
