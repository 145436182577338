import { FC } from 'react'
import styled, { css } from 'styled-components'
import Modal from '@app.components/Modal'
import useSignupCookies from '../../hook/useSignupCookies'

interface IFProps {
  isOpen: boolean
  onClickCancel: () => void
  onClickConfirm: () => void
}

const SingupExitModalDesktop: FC<IFProps> = ({
  isOpen,
  onClickCancel,
  onClickConfirm,
}) => {
  const { addSignupModalCookie } = useSignupCookies()

  const handleClickConfirm = () => {
    onClickConfirm()
    addSignupModalCookie()
  }
  return (
    <Modal open={isOpen} onClose={onClickCancel}>
      <StyledWrapper>
        <p>
          입력된 내용은 저장되지 않습니다. <br />
          종료하시겠습니까?
        </p>
        <div className="button-wrap">
          <button
            type="button"
            className="button-cancel"
            onClick={onClickCancel}
          >
            취소
          </button>
          <button
            type="button"
            className="button-confirm"
            onClick={handleClickConfirm}
          >
            종료
          </button>
        </div>
      </StyledWrapper>
    </Modal>
  )
}

export default SingupExitModalDesktop

const StyledWrapper = styled.aside`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 360px;
    height: 227px;
    padding: 50px 0 40px 0;
    border-radius: 20px;
    background-color: white;
    border-radius: 20px;

    & > p {
      font-size: 20px;
      font-weight: normal;
      line-height: 1.5;
      letter-spacing: -0.8px;
      text-align: center;
      color: ${theme.color.text_1};
      margin-bottom: 20px;
    }

    & > .button-wrap {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin: 0 auto;

      & > button {
        height: 50px;
        padding: 13px 30px;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.64px;
        text-align: center;
        color: #ffffff;
        border: 0;
        border-radius: 8px;
        cursor: pointer;

        &.button-cancel {
          min-width: 88px;
          background-color: white;
          border: solid 1px ${theme.line.line_2};
          color: #333;
        }

        &.button-confirm {
          min-width: 88px;
          background-color: ${theme.color.primary_2};
        }
      }
    }
  `}
`
