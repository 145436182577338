import { FC } from 'react'
import styled from 'styled-components'
import {
  ActivityScrap_CurrentUserFragment,
  RecommendActivity_S_Banner_ActivityFragment,
  RecommendActivity_A_Banner_ActivityFragment,
} from 'generated/graphql'
import A_Banner from './A_Banner'
import S_Banner from './S_Banner'

interface IFRecommendActivityListProps {
  sBanner?: RecommendActivity_S_Banner_ActivityFragment
  aBanners: RecommendActivity_A_Banner_ActivityFragment[]
  sBannerLoading: boolean
  aBannerLoading: boolean
  currentUser?: ActivityScrap_CurrentUserFragment
}

const RecommendActivityList: FC<IFRecommendActivityListProps> = ({
  sBanner,
  aBanners,
  sBannerLoading,
  aBannerLoading,
  currentUser,
}) => {
  return (
    <StyledWrapper>
      <h2 className="activity-title">링커리어 추천활동</h2>
      <div className="activity-banner-container">
        <S_Banner
          activity={sBanner}
          loading={sBannerLoading}
          currentUser={currentUser}
        />
        <A_Banner
          activities={aBanners}
          loading={aBannerLoading}
          currentUser={currentUser}
        />
      </div>
    </StyledWrapper>
  )
}

export default RecommendActivityList

const StyledWrapper = styled.div`
  .activity-title {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.96px;
    padding-bottom: 30px;
  }
  .activity-banner-container {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: calc(100% + 24px);
    margin: -12px;
  }
`
