import { TJobCategoryState } from '../../type/jobCategoryStoreType'

const unSelectIdWithChildren = (
  id: string,
  jobCategoryState: TJobCategoryState,
): Partial<TJobCategoryState> => {
  const targetCategory = jobCategoryState.categories[id]

  if (!targetCategory) {
    console.error('unSelectId function error. not found category')
    return jobCategoryState
  }

  let result = jobCategoryState

  targetCategory.selectedChildrenIds.map((childrenId) => {
    const childrenCategory = result.categories?.[childrenId]

    if (childrenCategory) {
      const childrenState = unSelectIdWithChildren(childrenId, result)
      result = {
        ...result,
        categories: {
          ...result.categories,
          ...childrenState.categories,
        },
        showSelectedIds: [
          ...(childrenState.showSelectedIds || result.showSelectedIds),
        ],
      }
    }
  })

  targetCategory.selectedChildrenIds = []

  targetCategory.selectedChildrenIds.map((selectedChildrenId) => {
    result.showSelectedIds = result.showSelectedIds.filter(
      (showSelectId) => showSelectId !== selectedChildrenId,
    )
  })

  result.showSelectedIds = result.showSelectedIds.filter(
    (showSelectId) => showSelectId !== id,
  )
  targetCategory.isSelect = false

  return result
}

export default unSelectIdWithChildren
