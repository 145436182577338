import { FC } from 'react'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import gql from 'graphql-tag'
import isNil from 'lodash/isNil'
import styled from 'styled-components'
import { numberWithCommas } from 'utils'
import {
  ActivityListCardItem_SecondInfoText_ActivityFragment,
  ActivityRecruitType,
} from 'generated/graphql'
import useIsMobile from '@app.modules/hooks/useIsMobile'
import { getLimitNumText } from './module/getLimitNumText'

gql`
  fragment ActivityListCardItem_SecondInfoText_activity on Activity {
    recruitType
    recruitCloseAt
    viewCount
    replyCount
  }
`

const getDDay = (closeDate?: number) => {
  if (!closeDate) return null

  const nowDate = new Date()
  return differenceInCalendarDays(closeDate, nowDate.getTime())
}

const getDDayText = (dDay?: number, recruitType?: ActivityRecruitType) => {
  if (!isNil(dDay)) {
    if (dDay < 0) return '마감'
    if (dDay === 0) {
      return '오늘마감'
    }
    return `D-${dDay > 999 ? 999 : dDay}`
  }

  switch (recruitType) {
    case ActivityRecruitType.ALWAYS:
      return '상시'
    case ActivityRecruitType.ASAP:
      return '선착순'
    case ActivityRecruitType.SCHEDULED:
      return '모집예정'
    default:
      return '모집정보 없음'
  }
}

export interface SecondInfoTextProps {
  activityInfos: ActivityListCardItem_SecondInfoText_ActivityFragment
  closeDateHighLightClassName?: string
}

const SecondInfoText: FC<SecondInfoTextProps> = ({
  activityInfos,
  closeDateHighLightClassName,
}) => {
  const isMobile = useIsMobile()
  const dDayByCloseAt = getDDay(activityInfos?.recruitCloseAt ?? undefined)
  const isAlmostClosed = !isNil(dDayByCloseAt) && dDayByCloseAt <= 5

  const replyCount = isMobile
    ? getLimitNumText({ number: activityInfos?.replyCount ?? 0, limit: 99 })
    : numberWithCommas(activityInfos?.replyCount ?? 0)
  const viewCount = isMobile
    ? getLimitNumText({ number: activityInfos?.viewCount, limit: 99999 })
    : numberWithCommas(activityInfos?.viewCount ?? 0)

  return (
    <StyledWrapper>
      <div
        className={
          isAlmostClosed
            ? `${closeDateHighLightClassName} close-date-highlight`
            : ''
        }
      >
        {getDDayText(dDayByCloseAt ?? undefined, activityInfos?.recruitType)}
      </div>
      <div>조회 {viewCount}</div>
      <div>댓글 {replyCount}</div>
    </StyledWrapper>
  )
}

export default SecondInfoText

const StyledWrapper = styled.div`
  display: flex;
  gap: 10px;
  font-size: 12px;
  color: #999;

  .close-date-highlight {
    font-weight: bold;
    color: #ef2929;
  }
`
