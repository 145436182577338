import * as yup from 'yup'
import { ActivityTypeID } from 'constants/enums'

const signupModalSchema = yup.object({
  preferJobCategoryIds: yup.array().of(yup.string()).min(1),

  preferActivityTypes: yup.array().of(yup.number()).min(1),

  preferOrganizationIds: yup
    .array()
    .of(yup.string())
    .nullable()
    .test({
      name: 'is-checked-recruit',
      test: function (value) {
        const { preferActivityTypes } = this.parent
        if (
          preferActivityTypes &&
          preferActivityTypes.includes(ActivityTypeID.RECRUIT)
        ) {
          return (value ?? []).length >= 1
        }
        return true
      },
    }),

  schoolType: yup.string().required(),
  school: yup.object({
    id: yup.string().required(),
    name: yup.string(),
  }),

  subscribeSMS: yup.boolean(),

  phoneNumber: yup.string().required(),
  phoneInput: yup.string().test({
    name: 'phone-input',
    test: function (inputValue) {
      const { phoneNumber } = this.parent
      if (phoneNumber || (inputValue && inputValue.length >= 10)) {
        return true
      }
      return false
    },
    message: '번호를 올바르게 입력해주세요',
  }),
  phoneVerification: yup.string(),
})

export default signupModalSchema
