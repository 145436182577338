import { FC } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import {
  useGqlLiveActivityInfoMobileQuery,
  ActivityListItem_CurrentUserFragment,
  AdType,
  AdStatus,
} from 'generated/graphql'
import ActivityListItem from 'components/ActivityListItem'

gql`
  fragment LiveActivityInfoMobile_advertisement on Advertisement {
    id
    title
    outLink
    pcImage
    mobileImage
  }

  fragment LiveActivityInfoMobile_activity on Activity {
    ...ActivityListItem_activity
    advertisement(adType: $adType) {
      ...LiveActivityInfoMobile_advertisement
    }
  }

  query gqlLiveActivityInfoMobile($adType: AdType!, $status: AdStatus) {
    advertisementList(filterBy: { adType: $adType, status: $status }) {
      activities {
        ...LiveActivityInfoMobile_activity
      }
    }
  }
`

interface IFLiveActivityInfoMobileProps {
  currentUser?: ActivityListItem_CurrentUserFragment
}

const LiveActivityInfoMobile: FC<IFLiveActivityInfoMobileProps> = ({
  currentUser,
}) => {
  const { data, loading } = useGqlLiveActivityInfoMobileQuery({
    variables: {
      adType: AdType.LIVE_ACTIVITY_INFO,
      status: AdStatus.OPEN,
    },
  })
  const activities = data?.advertisementList?.activities

  return (
    <StyledWrapper>
      <h2 className="title-text">실시간 활동정보</h2>
      {loading &&
        new Array(8)
          .fill(0)
          .map((_, index) => <ActivityListItem key={index} isLoading />)}
      {!loading && activities && (
        <div>
          {activities.map((activity) => {
            return (
              <div key={activity.id} className="activity-item">
                <ActivityListItem
                  activity={activity}
                  currentUser={currentUser}
                  showActivityType
                  showHotIcon={!!activity.advertisement}
                  {...(activity.advertisement?.title && {
                    itemTitle: activity.advertisement?.title,
                  })}
                  {...(activity.advertisement?.outLink && {
                    linkProps: {
                      onClick: () => {
                        if (activity.advertisement?.outLink) {
                          window.open(activity.advertisement.outLink)
                        }
                      },
                    },
                  })}
                  isLoading={loading}
                />
              </div>
            )
          })}
        </div>
      )}
    </StyledWrapper>
  )
}

export default LiveActivityInfoMobile

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  background-color: #fff;
  .title-text {
    font-size: 16px;
    font-weight: bold;
    padding: 0 0 10px 20px;
  }
  .activity-item {
    border-top: 1px solid #e8e8e8;
  }
`
