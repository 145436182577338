import { FC, useEffect, useState } from 'react'
import { useDomain } from 'context'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import URI from 'urijs'
import { LINKAREER_BIZ_BASE_URL } from 'utils/config'

const useReferrer = () => {
  const [referrer, setReferrer] = useState<string | null>(null)

  useEffect(() => {
    setReferrer(document.referrer)
  }, [])
  return referrer
}

const MyActivitiesNotLoggedin: FC = () => {
  const router = useRouter()
  const referrer = useReferrer()
  const { protocol, linkareer } = useDomain()

  const isUrl = (url: string) => {
    return /^(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/.test(
      decodeURIComponent(url),
    )
  }

  const getLinkQueryString = () => {
    const next = router.query.next as string
    if (next) {
      if (isUrl(next)) {
        return `?next=${encodeURIComponent(next)}`
      }

      return `?next=${encodeURIComponent(protocol + '://' + linkareer + next)}`
    }

    if (
      referrer &&
      URI(referrer).domain() === URI(document.location.href).domain()
    ) {
      return `?next=${referrer}`
    }

    return ''
  }

  return (
    <StyledWrapper>
      <div className="login-box">
        <Link href="/login">
          <a className="user-login">
            <Image
              src="/static/images/new_main/icon/ico-user.png"
              alt="개인회원 로그인"
              width={32}
              height={32}
            />
            <span>개인회원 로그인</span>
          </a>
        </Link>
        <Link
          href={`${protocol}://${
            LINKAREER_BIZ_BASE_URL || 'localhost:8090'
          }/login${getLinkQueryString()}`}
        >
          <a className="user-login">
            <Image
              src="/static/images/new_main/icon/ico-company.png"
              alt="기업회원 로그인"
              width={32}
              height={32}
            />
            <span>기업회원 로그인</span>
          </a>
        </Link>
      </div>
      <Link href="/signup/select">
        <a className="signup-box">개인 · 기업 회원가입</a>
      </Link>
    </StyledWrapper>
  )
}

export default MyActivitiesNotLoggedin

const StyledWrapper = styled.div`
  cursor: pointer;
  .login-box {
    display: flex;
    .user-login {
      width: 100%;
      height: 83px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      :first-child {
        border-right: solid 1px #e8e8e8;
      }
      span {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.42;
        letter-spacing: -0.48px;
        color: #333;
      }
    }
  }
  .signup-box {
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    line-height: 1.42;
    letter-spacing: -0.48px;
    color: #333;
    background-color: #f9f9f9;
    border-top: solid 1px #e8e8e8;
    border-radius: 0 0 5px 5px;
  }
`
