import { FC, memo, useRef } from 'react'
import styled from 'styled-components'
import { ArrowLeft, ArrowRight } from 'svgIcons'
import { SwiperClass } from 'swiper/react'
import {
  AdInfoList_AdFragment,
  useGqlAdsByPlacementCodeQuery,
} from 'generated/graphql'
import Skeleton from '@app.components/Skeleton'
import shuffleAdImage from '@app.feature/home/shuffleAdImage'
import { AD_CODE } from 'app.constants/adData'
import { mobileBreakPoint } from 'app.styled/media'
import NewBannerSwiper from './NewBannerSwiper'

const NewBannerDesktop: FC = () => {
  const swiperRef = useRef<SwiperClass | null>(null)
  const { data, loading } = useGqlAdsByPlacementCodeQuery({
    variables: {
      code: AD_CODE.main.pc,
    },
  })

  const mainDesktopAdList = shuffleAdImage<AdInfoList_AdFragment>(
    data?.adsByPlacementCode.nodes ?? [],
  )

  const goNext = () => {
    swiperRef.current?.slideNext()
  }

  const goPrev = () => {
    swiperRef.current?.slidePrev()
  }

  if (mainDesktopAdList.length < 1 || loading) {
    return <Skeleton width={350} height={500} />
  }

  return (
    <StyledWrapper>
      <button className="swiper-button-left" onClick={goPrev}>
        <ArrowLeft className="arrow-icon" />
      </button>
      <button className="swiper-button-right" onClick={goNext}>
        <ArrowRight className="arrow-icon" />
      </button>
      <NewBannerSwiper
        ref={swiperRef}
        mainDesktopAdList={mainDesktopAdList}
        totalCount={data?.adsByPlacementCode.totalCount ?? 0}
      />
    </StyledWrapper>
  )
}

export default memo(NewBannerDesktop)

const StyledWrapper = styled.div`
  position: relative;
  width: 345px;

  .swiper-button-left,
  .swiper-button-right {
    position: absolute;
    z-index: 2;
    width: 32px;
    height: 32px;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border: none;
    cursor: pointer;
    ${mobileBreakPoint} {
      display: none;
    }
  }

  .swiper-button-left {
    top: 200px;
    left: -15px;
  }
  .swiper-button-right {
    top: 200px;
    right: -15px;
  }

  .arrow-icon {
    width: 10px;
    height: 10px;
  }
`
