import { FC, useCallback, useRef, useState } from 'react'
import { format } from 'date-fns'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { ArrowLeft, ArrowRight } from 'svgIcons'
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useManagerReplyQuery, ActivityStatus } from 'generated/graphql'
import ManagerReplyItem from './ManagerReplyItem'
import ManagerReplyLoading from './ManagerReplyLoading'

gql`
  query ManagerReply($input: ReplyQnaInput!) {
    replyQna(input: $input) {
      ...ManagerReply_reply
    }
  }

  fragment ManagerReply_reply on Reply {
    ...ManagerReply_replyItem
    parent {
      ...ManagerReply_replyItem
    }
    activity {
      ...ManagerReply_activity
    }
  }

  fragment ManagerReply_replyItem on Reply {
    id
    content
    name
  }

  fragment ManagerReply_activity on Activity {
    id
    title
    type
    logoImage {
      id
      url
    }
    thumbnailImage {
      id
      url
    }
    recruitCloseAt
  }
`

const ManagerReply: FC = () => {
  const prevButtonRef = useRef<HTMLButtonElement>(null)
  const nextButtonRef = useRef<HTMLButtonElement>(null)
  const [totalLength, setTotalLength] = useState<number | undefined>(0)
  const [activeIndex, setActiveIndex] = useState<number | undefined>(1)

  SwiperCore.use([Pagination, Autoplay, Navigation, Pagination])
  const { data, loading, error } = useManagerReplyQuery({
    variables: {
      input: {
        replyCount: 10,
        activityStatus: ActivityStatus.OPEN,
      },
    },
  })

  const handleSlideChange = useCallback((swiper) => {
    setActiveIndex(swiper.realIndex + 1)
  }, [])

  const handleSwiper = useCallback((swiper) => {
    setTotalLength(swiper.slides.length)
  }, [])

  return !loading && data?.replyQna && !error ? (
    <StyledWrapper>
      <div className="title-wrapper">
        <div>
          <p className="manager-reply-title">담당자 댓글 Live</p>
          <p className="manager-reply-info">
            {format(new Date(), 'MM.dd hh:mm')} 기준
          </p>
        </div>
        <div className="button-container">
          <span className="active-count">
            {activeIndex}/{totalLength}
          </span>
          <button ref={prevButtonRef}>
            <ArrowLeft className="arrow-icon" />
          </button>
          <button ref={nextButtonRef}>
            <ArrowRight className="arrow-icon" />
          </button>
        </div>
      </div>
      <Swiper
        modules={[Pagination, Autoplay, Navigation]}
        loop
        slidesPerView={1}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        navigation={{
          prevEl: prevButtonRef.current,
          nextEl: nextButtonRef.current,
        }}
        onSlideChange={handleSlideChange}
        onSwiper={handleSwiper}
      >
        {data?.replyQna?.map((reply) => {
          return (
            <SwiperSlide key={reply.id}>
              <ManagerReplyItem reply={reply} />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </StyledWrapper>
  ) : (
    <ManagerReplyLoading />
  )
}

export default ManagerReply

const StyledWrapper = styled.div`
  width: 355px;
  height: 158px;

  button {
    width: 28px;
    border: solid 1px #ebebeb;
    padding: 5px 5px;
    border-radius: 3px;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.5s ease;
    :hover {
      background-color: #ebebeb;
    }
  }
  .arrow-icon {
    width: 10px;
    height: 10px;
  }
  p {
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.6px;
  }
  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 15px;
  }
  .manager-reply-title {
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    margin-right: 10px;
    cursor: default;
  }

  .manager-reply-info {
    display: inline;
    font-size: 12px;
    color: #666;
    cursor: default;
  }

  .active-count {
    font-size: 14px;
    letter-spacing: -0.56px;
    line-height: 20px;
    font-weight: bold;
    padding-right: 8px;
  }
`
