import { FC, memo, useCallback, useMemo, useRef, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import SwiperCore, { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react'
import {
  AdInfoList_AdFragment,
  useGqlAdsByPlacementCodeQuery,
} from 'generated/graphql'
import Skeleton from '@app.components/Skeleton'
import shuffleAdImage from '@app.feature/home/shuffleAdImage'
import { AD_CODE } from 'app.constants/adData'

const NewBannerMobile: FC = () => {
  SwiperCore.use([Autoplay, Pagination])
  const router = useRouter()
  const { data, loading } = useGqlAdsByPlacementCodeQuery({
    variables: {
      code: AD_CODE.main.mo,
    },
  })

  const swiperRef = useRef<SwiperClass | null>(null)
  const [, setCurrentSlideIndex] = useState(0)

  const handleSlideChange = (swiper) => {
    setCurrentSlideIndex(swiper.activeIndex)
  }

  const handleClickAd = useCallback(
    (url: string) => {
      window.open(url)
    },
    [router],
  )

  const mainMobileAdList = useMemo(
    () =>
      shuffleAdImage<AdInfoList_AdFragment>(
        data?.adsByPlacementCode.nodes ?? [],
      ),
    [data],
  )

  if (mainMobileAdList.length < 1 || loading) {
    return (
      <StyledSkeleton>
        <Skeleton className="skeleton" />
      </StyledSkeleton>
    )
  }

  return (
    <StyledWrapper>
      <Swiper
        modules={[Autoplay]}
        slidesPerView={1}
        onSlideChange={handleSlideChange}
        loop={true}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper
        }}
      >
        {mainMobileAdList.map((ad, index) => {
          const slideId = `swiper-new-banner-slide-${ad.id}`
          const landingUrl = ad.activity?.id
            ? `/activity/${ad.activity?.id ?? ''}`
            : router.pathname

          return (
            <SwiperSlide key={slideId} id={slideId}>
              <div className="ad-wrapper">
                <Link href={landingUrl}>
                  <img
                    className="main-banner-ad"
                    src={ad.image?.url}
                    alt={ad.image?.name}
                    onClick={() => handleClickAd(ad.campaign?.landingURL ?? '')}
                  />
                </Link>
                <div className="custom-pagination">{`${index + 1}/${
                  data?.adsByPlacementCode.totalCount
                }`}</div>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </StyledWrapper>
  )
}

export default memo(NewBannerMobile)

const StyledWrapper = styled.div`
  padding: 15px 0px;

  .ad-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    > img {
      cursor: pointer;
    }
    .custom-pagination {
      position: absolute;
      z-index: 1;
      cursor: default;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 22px;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 11px;
      padding: 2px 10px;
      right: 25px;
      bottom: 5%;
      color: white;
      font-size: 12px;
      font-weight: bold;
      line-height: 2;
    }
  }

  .main-banner-ad {
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
    max-height: 220px;
    height: calc(160px + (200 - 160) * ((100vw - 700px) / (880 - 700)));
  }
`
const StyledSkeleton = styled.div`
  padding: 15px 15px;
  .skeleton {
    box-sizing: border-box;
    width: 100%;
    max-height: 220px;
    height: calc(160px + (200 - 160) * ((100vw - 700px) / (880 - 700)));
  }
`
