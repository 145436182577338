import { uniq } from 'lodash'
import { TJobCategoryState } from '../../type/jobCategoryStoreType'
import doCategoriesSelectChange from './doCategoriesSelectChange'

const selectId = (
  id: string,
  jobCategoryState: TJobCategoryState,
): Partial<TJobCategoryState> => {
  const targetCategory = jobCategoryState.categories[id]

  if (!targetCategory) {
    console.error('selectId function error. not found category')
    return jobCategoryState
  }

  const isAlreadyExist = jobCategoryState.showSelectedIds.findIndex(
    (selectedId) => selectedId === id,
  )
  if (isAlreadyExist !== -1) {
    return jobCategoryState
  }

  const categories = doCategoriesSelectChange({
    targetCategoryId: id,
    action: 'select',
    categories: jobCategoryState.categories,
  })

  const showSelectedIds = uniq([id, ...jobCategoryState.showSelectedIds])

  const result = {
    ...jobCategoryState,
    categories,
    showSelectedIds,
  }

  return result
}

export default selectId
