import { useCookies } from 'react-cookie'
import { addDays, startOfDay } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { TIMEZONE } from 'utils/config'

export const SIGNUP_MODAL_COOKIE = 'signup-modal'

const useSignupCookies = () => {
  const [_, setCookies] = useCookies()

  const getCookieDomain = () => {
    const url = window.location.href

    if (url.includes('linkareer')) return '.linkareer.com'
    if (url.includes('lnkr')) return '.lnkr.cc'
    return 'localhost'
  }

  const setSignupCookie = (cookie: string, expires: Date) => {
    setCookies(cookie, Date.now(), {
      expires,
      domain: getCookieDomain(),
      path: '/',
    })
  }

  const addSignupModalCookie = () => {
    const oneDay = zonedTimeToUtc(startOfDay(addDays(new Date(), 1)), TIMEZONE) // 다음 날 자정 만료

    setSignupCookie(SIGNUP_MODAL_COOKIE, oneDay)
  }

  return {
    addSignupModalCookie,
  }
}

export default useSignupCookies
