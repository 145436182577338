import { FC } from 'react'
import ReactMarkdown from 'react-markdown'
import { makeStyles, Theme } from '@material-ui/core/styles'
import rehypeRaw from 'rehype-raw'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(15),
    lineHeight: '1.73em',
    '& h1': {
      fontSize: theme.typography.pxToRem(30),
      lineHeight: '1.5em',
      fontWeight: 'bold',
      marginBottom: 10,
    },
    '& h2': {
      lineHeight: '1.5em',
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 'bold',
      marginBottom: 10,
      marginTop: 30,
    },
    '& h3': {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 'bold',
    },
    '& h4': {
      marginTop: 10,
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 'bold',
      textAlign: 'right',
    },
    '& ol': {
      paddingInlineStart: '2em',
    },
    '& li': {
      color: theme.palette.text.secondary,
    },
    '& ul': {
      paddingInlineStart: '2em',
      listStyle: 'circle',
    },
    '& p': {
      color: theme.palette.text.secondary,
    },
    '& hr': {
      backgroundColor: '#e8e8e8',
      height: 1,
    },
    '& a': {
      color: theme.palette.text.secondary,
    },
    '& th': {
      padding: 8,
    },
    '& td': {
      padding: 8,
    },
  },
}))

interface MarkdownViewerProps {
  content: string
}
export const MarkdownViewer: FC<MarkdownViewerProps> = ({ content }) => {
  const classes = useStyles()

  return (
    <ReactMarkdown className={classes.root} rehypePlugins={[rehypeRaw]}>
      {content}
    </ReactMarkdown>
  )
}

export default MarkdownViewer
