import styled from 'styled-components'
import FieldPhone from '../../../../signup/signup/components/signupInfo/FieldPhone'
import SubscribeSMSDesktop from './SubscribeSMS.desktop'

const ReceiveGuideDesktop = () => {
  return (
    <StyledWrapper>
      <FieldPhone subTitle="아이디 및 비밀번호를 찾거나 변경할 때 필요합니다" />
      <SubscribeSMSDesktop />
    </StyledWrapper>
  )
}
export default ReceiveGuideDesktop

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
