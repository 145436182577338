import { FC } from 'react'
import gql from 'graphql-tag'
import styled, { css } from 'styled-components'
import { MyActivities_CurrentUserFragment, UserType } from 'generated/graphql'
import { GqlCommunityCurrentUserQuery } from 'generated/graphql.community'
import Skeleton from '@app.components/Skeleton'
import MyActivitiesManager from './MyActivitiesManager'
import MyActivitiesNotLoggedin from './MyActivitiesNotLoggedin'
import MyActivitiesUser from './MyActivitiesUser'

gql`
  fragment MyActivities_currentUser on User {
    id
    type
    ...MyActivitiesManager_currentUser
    ...MyActivitiesUser_currentUser
  }
`

interface IFMyActivitiesProps {
  currentUser: MyActivities_CurrentUserFragment | undefined | null
  communityUser?: GqlCommunityCurrentUserQuery
  isLoading: boolean
}

const MyActivities: FC<IFMyActivitiesProps> = ({
  currentUser,
  communityUser,
  isLoading,
}) => {
  const isManager = currentUser?.type === UserType.MANAGER_UNVERIFIED

  return (
    <StyledWrapper>
      {isLoading && <Skeleton width="100%" height="100%" />}
      {!isLoading && (
        <>
          {(!currentUser || !communityUser) && <MyActivitiesNotLoggedin />}
          {currentUser && communityUser && (
            <>
              {isManager && <MyActivitiesManager currentUser={currentUser} />}
              {!isManager && (
                <MyActivitiesUser
                  currentUser={currentUser}
                  communityUser={communityUser}
                />
              )}
            </>
          )}
        </>
      )}
    </StyledWrapper>
  )
}
export default MyActivities

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    max-width: 350px;
    min-height: 122px;
    border: 1px solid ${theme.line.line_3};
    border-radius: 5px;
  `}
`
