import { FC } from 'react'
import styled from 'styled-components'
import Skeleton from '@app.components/Skeleton'

const ManagerReplyLoading: FC = () => {
  return (
    <StyledWrapper>
      <div className="loading-top-wrapper">
        <div className="top-container">
          <p className="loading-reply-title">담당자 댓글 Live</p>
        </div>
        <Skeleton width={380} height={24} />
      </div>
      <Skeleton className="text-skeleton" width={355} height={50} />
      <Skeleton className="question-skeleton" width={355} height={20} />
      <Skeleton className="answer-skeleton" width={355} height={20} />
    </StyledWrapper>
  )
}

export default ManagerReplyLoading

const StyledWrapper = styled.div`
  .loading-top-wrapper {
    width: 355px;
    height: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .top-container {
    width: 200px;
    margin-right: 10px;
  }
  .loading-reply-title {
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    margin-right: 10px;
    cursor: default;
  }
  .date-skeleton {
    margin-left: 66px;
  }
  .text-skeleton,
  .question-skeleton,
  .answer-skeleton {
    margin-top: 18px;
  }
`
