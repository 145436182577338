import Image from 'next/image'
import styled from 'styled-components'
import { SERVICE_FEEDBACK_GOOGLE_FORM_URL } from 'constants/urls'

const ServiceFeedbackBanner = () => {
  return (
    <StyledWrapper
      href={SERVICE_FEEDBACK_GOOGLE_FORM_URL}
      target="_blank"
      rel="noreferrer"
    >
      <p>
        새로운 기능
        <br />
        만들어주세요!
      </p>
      <Image
        src="/static/images/service-feedback/emoji.png"
        width={52}
        height={48}
        alt="service-feedback"
      />
    </StyledWrapper>
  )
}
export default ServiceFeedbackBanner

const StyledWrapper = styled.a`
  position: absolute;
  top: 534px;
  left: calc(100% + 20px);
  display: flex;
  width: 160px;
  height: 64px;
  padding: 5px 7px 12px 14px;
  border-radius: 5px;
  background-color: #3e54ff;
  align-items: flex-end;

  > p {
    font-size: 15px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: -0.6px;
    color: white;
  }
`
