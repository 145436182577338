import { FC } from 'react'
import styled from 'styled-components'
import ActivityLoadingItem from '@app.feature/home/components/ActivityLoadingItem/ActivityLoadingItem'
import ActivityListItem from '@app.feature/recruitList/component/recruitListTable/component/recruitListTableMobile/component/ActivityListItem.mobile'

interface IFProps {
  title: string
  isCard?: boolean
}

const ActivityLoadingMobile: FC<IFProps> = ({ title, isCard = true }) => {
  return (
    <StyledWrapper>
      <div className="title-text">{title}</div>
      {isCard && (
        <div className="loading-list">
          <ActivityLoadingItem time={3} className="loading-item" />
        </div>
      )}
      {!isCard && (
        <div>
          {new Array(8).fill(0).map((_, index) => (
            <ActivityListItem key={index} isLoading />
          ))}
        </div>
      )}
    </StyledWrapper>
  )
}

export default ActivityLoadingMobile

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  overflow: hidden;
  background-color: #fff;
  border-bottom: 15px solid #fafafa;

  .title-text {
    padding: 0 20px 10px;
    font-size: 16px;
    font-weight: bold;
  }
  .loading-list {
    display: flex;
  }
  .loading-item {
    width: 45.4545%;
    padding: 5px;
    flex-shrink: 0;
    &:first-child {
      margin-left: 15px;
    }
    &:last-child {
      margin-right: 15px;
    }
  }
`
