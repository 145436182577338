import {
  forwardRef,
  FC,
  memo,
  AnchorHTMLAttributes,
  Ref,
  ElementType,
} from 'react'
import MuiLink, { LinkProps as MuiLinkProps } from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { useRouter } from 'next/router'

type NextComposedProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> &
  NextLinkProps

const NextComposed = forwardRef<HTMLAnchorElement, NextComposedProps>(
  (props, ref) => {
    const { as, href, replace, scroll, passHref, shallow, prefetch, ...other } =
      props

    return (
      <NextLink
        href={href}
        prefetch={prefetch}
        as={as}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        passHref={passHref}
      >
        <a ref={ref} {...other} />
      </NextLink>
    )
  },
)
NextComposed.displayName = 'NextComposed'

interface LinkPropsBase {
  activeClassName?: string
  innerRef?: Ref<HTMLAnchorElement>
  naked?: boolean
  renderATag?: boolean
  colorInherit?: boolean
  component?: ElementType
}

export type LinkProps = LinkPropsBase &
  NextComposedProps &
  Omit<MuiLinkProps, 'ref' | 'href'>

const useStyles = makeStyles(() => ({
  inherit: {
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
      textDecoration: 'none !important',
    },
  },
}))

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/#with-link
const Link: FC<LinkProps> = (props) => {
  const {
    colorInherit = false,
    renderATag = false,
    activeClassName = 'active',
    className: classNameProps,
    innerRef,
    naked,
    href,
    ...other
  } = props
  const classes = useStyles()

  const router = useRouter()
  const pathname = typeof href === 'string' ? href : href.pathname
  const className = clsx(classNameProps, {
    [classes.inherit]: colorInherit,
    [activeClassName]: router.pathname === pathname && activeClassName,
  })

  if (renderATag) {
    if (typeof href !== 'string') {
      console.error(
        `Warning: Failed prop type: Invalid prop \`href\` of type \`${typeof href}\` supplied to` +
          `\`components/Link\` + renderATag: true, expected \`string\``,
      )
    }
    return (
      <a
        href={href as string}
        className={className}
        ref={innerRef}
        {...other}
      />
    )
  }

  if (naked) {
    return (
      <NextComposed
        href={href}
        className={className}
        ref={innerRef}
        {...other}
      />
    )
  }

  return (
    <MuiLink
      component={NextComposed}
      className={className}
      ref={innerRef}
      href={href as string}
      {...other}
    />
  )
}

const LinkWithRef = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
  <Link {...props} innerRef={ref} />
))

LinkWithRef.displayName = 'LinkWithRef'
export default memo(LinkWithRef)
