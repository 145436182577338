import { FC, useMemo, useState } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import {
  CommunityBestPostListType,
  useGqlCommunityBestPostQuery,
} from 'generated/graphql'
import BestStoryList, { TBestStoryItem } from '../BestStoryList'

gql`
  query gqlCommunityBestPost($input: CommunityBestPostListInput!) {
    communityBestPostList(input: $input) {
      data
    }
  }
`

const CommunityBestPost: FC = () => {
  const [currentTab, setCurrentTab] = useState(CommunityBestPostListType.RECENT)

  const { data, loading } = useGqlCommunityBestPostQuery({
    variables: {
      input: { type: currentTab, pagination: { page: 1, pageSize: 10 } },
    },
  })

  const PostBestTabs = useMemo(() => {
    return [
      {
        label: '실시간',
        active: currentTab === CommunityBestPostListType.RECENT,
        onClick: () => setCurrentTab(CommunityBestPostListType.RECENT),
      },
      {
        label: '주간',
        active: currentTab === CommunityBestPostListType.LAST_WEEK,
        onClick: () => setCurrentTab(CommunityBestPostListType.LAST_WEEK),
      },
      {
        label: '월간',
        active: currentTab === CommunityBestPostListType.LAST_MONTH,
        onClick: () => setCurrentTab(CommunityBestPostListType.LAST_MONTH),
      },
      {
        label: '댓글순',
        active: currentTab === CommunityBestPostListType.REPLY_COUNT,
        onClick: () => setCurrentTab(CommunityBestPostListType.REPLY_COUNT),
      },
      {
        label: '추천순',
        active: currentTab === CommunityBestPostListType.VOTE_COUNT,
        onClick: () => setCurrentTab(CommunityBestPostListType.VOTE_COUNT),
      },
    ]
  }, [currentTab])

  const dataSet: TBestStoryItem[] = useMemo(() => {
    return (
      data?.communityBestPostList?.data.bestPosts.map((post) => ({
        href: post.url,
        content: post.title.replace(/<[^>]*>?/g, ''),
        commentCount: post.commentCount,
      })) || []
    )
  }, [data])

  return (
    <StyledWrapper>
      <div className="tab-box">
        {PostBestTabs.map((tab) => (
          <button
            key={tab.label}
            className="tab"
            data-active={tab.active}
            onClick={tab.onClick}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <BestStoryList data={dataSet} loading={loading} />
    </StyledWrapper>
  )
}

export default CommunityBestPost

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0px;
  .tab-box {
    display: flex;
    gap: 16px;
    .tab {
      cursor: pointer;
      border: none;
      padding: 0px;
      background: none;
      font-size: 14px;
      font-weight: 500;
      color: #666;
      &[data-active='true'] {
        color: #01a0ff;
        font-weight: bold;
      }
      :hover {
        color: #01a0ff;
      }
    }
  }
`
