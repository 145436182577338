import { StoreJobCategoryRoot_CategoryFragment } from 'generated/graphql'
import {
  TIdsByDepth,
  TJobCategories,
  TJobCategory,
  TStoreSlice,
} from '@app.feature/jobCategory/type/jobCategoryStoreType'
import { activeId, convertCategoryToState, getCategoriesByIds } from '../module'
import resetState from '../module/resetState'
import setSelectedCategories from '../module/setSelectedCategories'

interface IFResetHardOptions {
  isHard?: boolean
}

export type TBaseSlice = {
  originalCategoriesData: StoreJobCategoryRoot_CategoryFragment[]
  categories: TJobCategories
  idsByDepth: TIdsByDepth
  activeIds: [] | [string] | [string, string]
  initCategories: (
    categories: StoreJobCategoryRoot_CategoryFragment[],
    selectedCategoryIds?: string[],
  ) => void
  activeId: (id: string) => void
  getCategoriesByIds: (ids: string[]) => (TJobCategory & { order: number })[]
  getChainingLabelById: (categoryId: string) => string
  getInternTypeLabelById: (internTypeId: string) => string
  resetState: (options?: IFResetHardOptions) => void
}

const createBaseSlice: TStoreSlice<TBaseSlice> = (set, get) => ({
  originalCategoriesData: [],
  categories: {},
  idsByDepth: [],
  activeIds: [],
  initCategories: (categories, selectedCategoryIds) => {
    if (categories) {
      const jobCategoryState = convertCategoryToState(categories, 1)

      let result = jobCategoryState
      if (selectedCategoryIds) {
        const defaultState = get()
        result = setSelectedCategories(selectedCategoryIds, {
          ...defaultState,
          ...result,
        })
        let order = 0
        const orderMapResult = selectedCategoryIds.reduce(
          (acc, cur) => ({
            ...acc,
            [cur]: order++,
          }),
          {},
        )
        result = {
          ...result,
          ...orderMapResult,
          order,
        }
      }

      set((state) => ({
        ...state,
        ...result,
        activeIds: ['56'], // 두번째 뎁스까지 기본값으로 열릴 수 있도록 합니다
        originalCategoriesData: categories,
      }))
    }
  },
  activeId: (id) => {
    const currentJobCategoryState = get()
    const updatedState = activeId(id, currentJobCategoryState)

    set((state) => ({
      ...state,
      ...updatedState,
    }))
  },
  getCategoriesByIds: (ids) => {
    const currentJobCategoryState = get()
    const result = getCategoriesByIds(ids, currentJobCategoryState.categories)

    return result.map((category) => ({
      ...category,
      order: currentJobCategoryState.getOrderByKey(category.id),
    }))
  },
  resetState: (options) => {
    const isHard = options?.isHard || false

    const currentJobCategoryState = get()

    const updatedState = resetState(currentJobCategoryState, isHard)

    set((state) => ({
      ...state,
      ...updatedState,
    }))
  },
  getChainingLabelById: (categoryId) => {
    const categories = get().categories
    if (!categories) return ''

    const { name, depth, parentId } = categories[categoryId]

    const nameArray = (() => {
      let currentDepth = depth
      let currentParentId = parentId
      const result = [name]

      while (currentParentId && currentDepth > 1) {
        const parent = categories[currentParentId]

        result.unshift(parent.name)
        currentDepth = parent.depth
        currentParentId = parent.parentId
      }

      return result
    })()

    return `${nameArray.join(' > ')}${depth !== 3 ? ' 전체' : ''}`
  },
  getInternTypeLabelById: (internTypeId) => {
    const INTERN_ID_TO_LABEL_MAP = {
      '1': '체험형 인턴',
      '2': '채용연계형 인턴',
      '3': '기타',
    }
    return INTERN_ID_TO_LABEL_MAP[internTypeId]
  },
})

export default createBaseSlice
