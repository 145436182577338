import { FC, ReactNode } from 'react'
import { PureQueryOptions } from '@apollo/client'
import {
  Typography,
  Box,
  IconButton,
  CircularProgress,
} from '@material-ui/core'
import clsx from 'clsx'
import format from 'date-fns/format'
import gql from 'graphql-tag'
import get from 'lodash/get'
import take from 'lodash/take'
import Image from 'next/image'
import { useRouter } from 'next/router'
import styled, { css } from 'styled-components'
import {
  ActivityListItem_CurrentUserFragment,
  ActivityListItem_ActivityFragment,
  ActivityRecruitType,
} from 'generated/graphql'
import Skeleton from '@app.components/Skeleton'
import useIsWebview from '@app.modules/hooks/useIsWebview'
import { getWebviewHref } from '@app.modules/util'
import { RECRUIT_LOGO_IMAGE_SIZE } from 'app.constants/recruitImageSize'
import ActivityScrap from 'components/ActivityScrap'
import Link, { LinkProps } from 'components/Link'
import OldChip from 'components/OldChip'
import { ActivityTypeID } from 'constants/enums'
import { ACTIVITY_TYPE_TEXT } from 'constants/typeText'
import useKeywordHighlighter from 'hooks/useKeywordHighlighter'
import BookMark from 'svgIcons/BookMark'
import { optimizeImageUrl, imageLoader } from 'utils/optimizeImageUrl'
import { getJobType } from 'utils/utils'

gql`
  fragment ActivityListItem_currentUser on User {
    ...ActivityScrap_currentUser
  }
  fragment ActivityListItem_activity on Activity {
    id
    type
    viewCount
    activityEndAt
    isScrapped
    title
    jobTypes
    recruitInformations {
      id
      jobType
      internTypes {
        id
        name
      }
    }
    recruitType
    recruitScale
    organizationName
    recruitCloseAt
    viewCount
    thumbnailImage {
      id
      url
    }
    logoImage {
      id
      url
    }
    categories {
      id
      name
    }
    regions {
      id
      name
    }
    regionDistricts {
      id
      name
      region {
        id
        name
      }
    }
    ...ActivityScrap_activity
  }
`

export interface ActivityListItemProps {
  className?: string
  isLoading?: boolean
  activity?: ActivityListItem_ActivityFragment
  currentUser?: ActivityListItem_CurrentUserFragment | null
  showActivityType?: boolean
  showHotIcon?: boolean
  size?: 'medium' | 'large'
  searchKeyword?: string
  renderSecondary?: () => any
  scrapIcon?: ReactNode
  showScrapCount?: boolean
  scrapRefetchQueries?: PureQueryOptions[]
  linkProps?: Partial<LinkProps>
  itemTitle?: string | null
  isRecommend?: boolean
}

const ActivityListItem: FC<ActivityListItemProps> = (props) => {
  const router = useRouter()
  const isWebview = useIsWebview()

  const {
    className = '',
    size = 'medium',
    isLoading,
    activity,
    currentUser,
    showActivityType = false,
    showHotIcon = false,
    searchKeyword = '',
    scrapIcon: initScrapIcon,
    showScrapCount = true,
    scrapRefetchQueries,
    linkProps,
    itemTitle,
    isRecommend,
  } = props
  const scrapIcon = initScrapIcon ?? (
    <BookMark
      className="icon-bookmark"
      isOn={activity?.isScrapped}
      strokeType="#666666"
    />
  )

  const activityTypeText = get(ACTIVITY_TYPE_TEXT, activity?.type || '', '')

  const isRecruit = activity?.type === ActivityTypeID.RECRUIT

  const categories = (activity?.categories ?? []).map(
    (category) => category?.name,
  )

  const highlightedTitle = useKeywordHighlighter({
    text: itemTitle || activity?.title || '',
    keyword: searchKeyword,
  })

  let viewCount = activity?.viewCount || '0'
  if (typeof viewCount === 'number' && viewCount > 9999) {
    viewCount = '999+'
  }

  const handleClickOrganization = () => {
    if (activity?.manager?.channel) {
      router.push(
        getWebviewHref(`/channel/${activity.manager.channel.url}`, isWebview),
      )
    }
  }

  function renderSecondary() {
    if (isRecruit) {
      let categoriesText = '-'
      if (categories.length > 1) {
        categoriesText = '각 부문'
      } else if (categories.length === 1 && categories[0]) {
        categoriesText = categories[0]
      }
      return (
        <Box
          display="flex"
          alignItems="center"
          maxWidth="100%"
          flexShrink={0}
          flexWrap="wrap"
        >
          <Box display="flex">
            <Box mr={0}>
              {isLoading ? (
                <Skeleton height={13} />
              ) : (
                <Typography color="textPrimary" className="short-info-typo">
                  {activity?.jobTypes?.length
                    ? getJobType(take(activity.jobTypes, 2))
                    : '-'}
                </Typography>
              )}
            </Box>

            {isLoading ? (
              <Skeleton width={100} height={13} />
            ) : (
              <Box flexGrow={1} display="flex">
                <Typography color="textPrimary" className="category">
                  {categoriesText}
                </Typography>
              </Box>
            )}
          </Box>
          <Box display="flex">
            {isLoading ? (
              <Skeleton height={13} />
            ) : (
              <Typography color="textPrimary" className="short-info-typo">
                {activity?.recruitType
                  ? {
                      [ActivityRecruitType.NORMAL]: activity?.recruitCloseAt
                        ? `~ ${format(
                            new Date(activity.recruitCloseAt),
                            'MM.dd',
                          )}`
                        : '-',
                      [ActivityRecruitType.ALWAYS]: '상시채용',
                      [ActivityRecruitType.ASAP]: '채용 시 마감',
                      [ActivityRecruitType.SCHEDULED]: '예정',
                    }[activity.recruitType]
                  : '-'}
              </Typography>
            )}
            {isLoading ? (
              <Skeleton height={13} />
            ) : (
              <Typography color="textPrimary" className="short-info-typo">
                조회 {viewCount}
              </Typography>
            )}
          </Box>
        </Box>
      )
    }

    return (
      <Box display="flex" alignItems="center" maxWidth="100%" flexShrink={0}>
        {isLoading ? (
          <>
            <Box mr={1}>
              <Skeleton width={40} height={13} />
            </Box>
            <Skeleton width={40} height={13} />
          </>
        ) : (
          <>
            <Typography color="textPrimary" className="short-info-typo">
              {activity?.recruitCloseAt
                ? format(activity.recruitCloseAt, '~M.dd')
                : '-'}
            </Typography>
            <Typography
              color="textPrimary"
              className={clsx('short-info-typo', 'view-count')}
            >
              조회 {viewCount}
            </Typography>
          </>
        )}
      </Box>
    )
  }

  return (
    <StyledWrapper
      className={clsx(className, {
        large: size === 'large',
      })}
      data-testid={
        isLoading ? 'activity-list-item-loading' : 'activity-list-item'
      }
      data-activityid={activity?.id}
    >
      <Box
        width={60}
        mr={1.875}
        flexShrink={0}
        alignItems="center"
        display="flex"
        onClick={handleClickOrganization}
      >
        {isLoading ? (
          <Skeleton width={60} height={60} />
        ) : (
          <Image
            loader={imageLoader}
            className="img-thumbnail"
            key={`live-activity-logo-image-${activity?.logoImage?.id}`}
            alt={activity?.title || ''}
            width={RECRUIT_LOGO_IMAGE_SIZE}
            height={RECRUIT_LOGO_IMAGE_SIZE}
            src={optimizeImageUrl({
              url: isRecruit
                ? activity?.logoImage?.url || ''
                : activity?.thumbnailImage?.url || '',
              width: RECRUIT_LOGO_IMAGE_SIZE,
              height: 'auto',
            })}
            objectFit="contain"
          />
        )}
      </Box>
      <Box flexGrow={1} display="flex" minWidth={0} alignItems="center">
        <Box
          mr={3.75}
          flexGrow={1}
          display="flex"
          minWidth={0}
          maxWidth="100%"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Box mr={3.75} mb={0.5} width="100%" flexGrow={0} flexShrink={0}>
            {isLoading ? (
              <Skeleton width={100} height={13} />
            ) : (
              <Box display="flex" alignItems="center">
                {showHotIcon && (
                  <OldChip
                    className={clsx('chip', 'hot')}
                    classes={{
                      label: 'label-chip',
                    }}
                    label={'HOT'}
                    color="primary"
                    size="small"
                  />
                )}
                {showActivityType && activityTypeText && (
                  <OldChip
                    className="chip"
                    classes={{
                      label: 'label-chip',
                    }}
                    label={activityTypeText}
                    color="primary"
                    size="small"
                  />
                )}
                <Typography
                  style={{
                    cursor:
                      activity?.manager?.channel === undefined ||
                      activity?.manager?.channel === null
                        ? 'default'
                        : 'pointer',
                  }}
                  color="textPrimary"
                  className="company-name"
                  onClick={handleClickOrganization}
                >
                  {activity?.organizationName}
                </Typography>
              </Box>
            )}
          </Box>
          <Box mr={3.75} flexGrow={1} width="100%" mb={0.5}>
            <Link
              className="link"
              {...(activity?.id
                ? {
                    href: `/activity/[id]`,
                    as: `/activity/${activity?.id}`,
                  }
                : { href: '' })}
              naked
              {...linkProps}
            >
              {isLoading ? (
                <Skeleton height={13} />
              ) : isRecommend ? (
                <div className="container">
                  <div className="recommend-tag-container">
                    <span className="recommend-text">추천</span>
                  </div>
                  <Typography
                    color="textPrimary"
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: highlightedTitle,
                    }}
                  />
                </div>
              ) : (
                <Typography
                  color="textPrimary"
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: highlightedTitle,
                  }}
                />
              )}
            </Link>
          </Box>
          {props?.renderSecondary?.() || renderSecondary()}
        </Box>
        <Box flexShrink={0}>
          <ActivityScrap
            activity={activity}
            currentUser={currentUser}
            refetchQueries={scrapRefetchQueries}
          >
            {({ scrapActivity, loading, scrapCount }) => (
              <>
                <IconButton
                  onClick={scrapActivity}
                  className={clsx('button-scrap', {
                    scrapped: activity?.isScrapped,
                  })}
                  disabled={loading}
                >
                  {!loading ? scrapIcon : <CircularProgress size={18} />}
                </IconButton>
                {showScrapCount &&
                  (isLoading ? (
                    <Skeleton
                      width={20}
                      className="scrap-count-skeleton"
                      height={13}
                    />
                  ) : (
                    <Typography
                      color="textSecondary"
                      className={clsx('scrap-count', {
                        scrapped: activity?.isScrapped,
                      })}
                    >
                      {scrapCount}
                    </Typography>
                  ))}
              </>
            )}
          </ActivityScrap>
        </Box>
      </Box>
    </StyledWrapper>
  )
}

export default ActivityListItem

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: #ffffff;
    padding: 15px 10px 15px 20px;
    display: flex;
    align-items: center;

    ${theme.breakpoints.up('md')} {
      padding-left: 0;
    }

    &.large {
      padding-top: 20px;
      padding-bottom: 20px;

      ${theme.breakpoints.down('sm')} {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }

    .company-name {
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      font-weight: 500;
    }

    .title {
      font-size: 16px;
      white-space: pre-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.2em;
      text-align: left;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      & .highlight {
        font-weight: 600;
      }
    }

    .link {
      &:hover {
        text-decoration: underline;
      }
    }

    .dot {
      font-size: 12px;
      font-weight: 300;
      margin-left: 5px;
      margin-right: 5px;
    }

    .category {
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      font-weight: 400;
      padding-right: 10px;
    }

    .short-info-typo {
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      font-weight: 400;
      padding-right: 10px;
    }

    .view-count {
      margin-left: 0;
    }

    .button-scrap {
      color: ${theme.line.line_3};
      padding-bottom: 5px;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: ${theme.color.primary_2};
        }
      }
    }

    .scrap-count {
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }

    .scrap-count-skeleton {
      margin: auto;
    }

    .scrapped {
      path {
        fill: ${theme.color.primary_2};
      }
    }

    .icon-bookmark {
      width: 15px;
      height: 15px;
      color: #666666;
    }

    .img-thumbnail {
      border-radius: 5px;
    }

    .chip {
      background-color: #bbbbbb;
      border: 0;
      border-radius: 3px;
      margin-right: 5px;
      height: auto;
    }

    .label-chip {
      line-height: 1em;
      font-size: 12px;
      padding: 3px;
      color: #ffffff;
      font-weight: 500;
    }

    .hot {
      background-color: #ef2929;
    }

    .recommend-tag-container {
      display: flex;
      padding: 2px;
      border: 1px solid ${theme.color.secondary_1};
      border-radius: 3px;
      margin-right: 5px;
      flex-shrink: 0;
    }

    .recommend-text {
      color: ${theme.color.secondary_1};
      font-size: 11px;
    }

    .container {
      display: flex;
    }
  `}
`
