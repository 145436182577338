import { TJobCategoryState } from '../../type/jobCategoryStoreType'
import selectId from './selectId'

const setSelectedCategories = (
  selectedCategoryIds: string[],
  jobCategoryState: TJobCategoryState,
): Partial<TJobCategoryState> => {
  const categories = jobCategoryState.categories

  selectedCategoryIds.map((id) => {
    const parentId = categories[id].parentId
    const existIndex = selectedCategoryIds.findIndex(
      (findId) => findId === parentId,
    )
    if (parentId && existIndex !== -1) {
      selectedCategoryIds.splice(existIndex, 1)
    }
  })
  let newJobCategoryState = { ...jobCategoryState }

  selectedCategoryIds.map((id) => {
    newJobCategoryState = {
      ...newJobCategoryState,
      ...selectId(id, newJobCategoryState),
    }
  })

  const result = {
    ...jobCategoryState,
    ...newJobCategoryState,
  }

  return result
}

export default setSelectedCategories
