import gql from 'graphql-tag'
import create from 'zustand'
import { devtools } from 'zustand/middleware'
import { TJobCategoryState } from '../type/jobCategoryStoreType'
import createBaseSlice from './slice/baseSlice'
import createOrderSlice from './slice/orderSlice'
import createSelectSlice from './slice/selectSlice'

gql`
  fragment StoreJobCategory_category on Category {
    id
    name
    seq
  }

  fragment StoreJobCategoryItem_category on Category {
    ...StoreJobCategory_category
    parent {
      ...StoreJobCategory_category
    }
    children {
      ...StoreJobCategory_category
    }
  }

  fragment StoreJobCategoryRoot_category on Category {
    ...StoreJobCategory_category
    children {
      ...StoreJobCategory_category
      children {
        ...StoreJobCategory_category
        parent {
          ...StoreJobCategory_category
        }
      }
      parent {
        ...StoreJobCategory_category
      }
    }
    parent {
      ...StoreJobCategory_category
    }
  }
`

const useJobCategoryStore = create<TJobCategoryState>()(
  devtools((set, get) => ({
    ...createBaseSlice(set, get),
    ...createOrderSlice(set, get),
    ...createSelectSlice(set, get),
  })),
)

export default useJobCategoryStore
