import { FC, useState } from 'react'
import gql from 'graphql-tag'
import Link from 'next/link'
import styled from 'styled-components'
import {
  useGqlLiveActivityInfoDesktopQuery,
  ActivityListItem_CurrentUserFragment,
  AdType,
  AdStatus,
} from 'generated/graphql'
import ActivityListItem from 'components/ActivityListItem'
import ExpandMoreIcon from 'svgIcons/ExpandMore'

gql`
  fragment LiveActivityInfoDesktop_advertisement on Advertisement {
    id
    title
    outLink
    pcImage
    mobileImage
  }

  fragment LiveActivityInfoDesktop_activity on Activity {
    ...ActivityListItem_activity
    advertisement(adType: $adType) {
      ...LiveActivityInfoDesktop_advertisement
    }
  }

  fragment LiveActivityInfoDesktop_advertisement on Advertisement {
    id
    title
    outLink
    pcImage
    mobileImage
  }

  query gqlLiveActivityInfoDesktop($adType: AdType!, $status: AdStatus) {
    advertisementList(filterBy: { adType: $adType, status: $status }) {
      activities {
        ...LiveActivityInfoDesktop_activity
      }
    }
  }
`

const MENU_LIST = [
  {
    label: '대외활동',
    href: '/list/activity',
  },
  {
    label: '공모전',
    href: '/list/contest',
  },
  {
    label: '동아리',
    href: '/list/club',
  },
  {
    label: '채용',
    href: '/list/recruit',
  },
  {
    label: '교육',
    href: '/list/education',
  },
]

interface IFLiveActivityInfoDesktopProps {
  currentUser?: ActivityListItem_CurrentUserFragment
}

const LiveActivityInfoDesktop: FC<IFLiveActivityInfoDesktopProps> = ({
  currentUser,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { data, loading } = useGqlLiveActivityInfoDesktopQuery({
    variables: {
      adType: AdType.LIVE_ACTIVITY_INFO,
      status: AdStatus.OPEN,
    },
  })
  const activities = data?.advertisementList?.activities

  return (
    <StyledWrapper>
      <section className="title-section">
        <h2 className="title-text">실시간 활동정보</h2>
        <button
          onClick={() => setIsMenuOpen((prev) => !prev)}
          className="menu-button"
        >
          전체보기
          <ExpandMoreIcon />
        </button>
        {isMenuOpen && (
          <div className="menu-drop-down">
            {MENU_LIST.map((menu) => (
              <Link href={menu.href} key={menu.label}>
                <a className="menu-item">{menu.label}</a>
              </Link>
            ))}
          </div>
        )}
      </section>
      {loading &&
        new Array(8)
          .fill(0)
          .map((_, index) => <ActivityListItem key={index} isLoading />)}
      {!loading && (
        <div>
          {activities?.map((activity) => (
            <article
              key={activity.id}
              className="activity-article"
              data-is-ad={!!activity.advertisement}
            >
              <ActivityListItem
                activity={activity}
                currentUser={currentUser}
                showActivityType
                showHotIcon={!!activity.advertisement}
                className="activity-item"
                {...(activity.advertisement?.title && {
                  itemTitle: activity.advertisement?.title,
                })}
                {...(activity.advertisement?.outLink && {
                  linkProps: {
                    onClick: () => {
                      if (activity.advertisement?.outLink) {
                        window.open(activity.advertisement.outLink)
                      }
                    },
                  },
                })}
                isLoading={loading}
              />
            </article>
          ))}
        </div>
      )}
    </StyledWrapper>
  )
}

export default LiveActivityInfoDesktop

const StyledWrapper = styled.div`
  .title-section {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .title-text {
      font-size: 20px;
      font-weight: bold;
    }
    .menu-button {
      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.63;
      letter-spacing: -0.64px;
      gap: 5px;
      cursor: pointer;
      svg {
        width: 14px;
      }
    }
    .menu-drop-down {
      position: absolute;
      top: 30px;
      right: 7px;
      display: flex;
      flex-direction: column;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      padding: 5px 0;
      z-index: 1;
      background-color: #fff;
      box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.15);
      .menu-item {
        padding: 5px 15px;
        font-size: 12px;
        font-weight: 500;
        :hover {
          background-color: #f2f2f2;
        }
      }
    }
  }
  .activity-article {
    border-top: 1px solid #eee;
    :last-child {
      border-bottom: 1px solid #eee;
    }
    .activity-item {
      padding-left: 20px;
      max-height: 93px;
    }
    &[data-is-ad='true'] {
      border-top: 1px solid #d6f0ff;
      .activity-item {
        background-color: #f2faff;
      }
    }
  }
`
